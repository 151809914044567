import { useEffect } from 'react';

import { useConfigurationStore } from '../configuration/store';
import NotificationItems from '../notificationItems/notificationItems';
import NotificationsHeader from '../notificationsHeader/notificationsHeader';
import { useNotificationsStore } from './store';
import {
  NotificationIconStyled,
  NotificationsStyled,
  NotificationsWrapperStyled,
  UnreadCountStyled,
  UnreadCountWrapperStyled,
} from './styles';

const Notifications = () => {
  const { feedClient } = useConfigurationStore();
  const { fetchNotifications } = useNotificationsStore();

  const unreadCount = feedClient?.store?.getState()?.metadata?.unread_count || 0;

  useEffect(() => {
    fetchNotifications();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Receive all real-time notifications on our feed
    feedClient?.on('items.received.realtime', fetchRealTimeNotifications);

    // Stop receiving all real-time notifications on our feed
    return () => feedClient?.off('items.received.realtime', fetchRealTimeNotifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedClient]);

  const fetchRealTimeNotifications = async () => {
    await fetchNotifications();
  };

  return (
    <NotificationsWrapperStyled>
      <NotificationsStyled
        title={<NotificationsHeader />}
        trigger='hover'
        placement='bottom'
        content={<NotificationItems />}
        autoAdjustOverflow={false}
      >
        <NotificationIconStyled />
        {unreadCount > 0 && (
          <UnreadCountWrapperStyled>
            <UnreadCountStyled>{unreadCount > 10 ? `10+` : unreadCount}</UnreadCountStyled>
          </UnreadCountWrapperStyled>
        )}
      </NotificationsStyled>
    </NotificationsWrapperStyled>
  );
};

export default Notifications;
