import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import ReactGa from 'react-ga4';

import { ENVIRONMENT } from '../globalConstants';
import { REACT_APP_ENV } from '../helpers/EnvironmentVariables';
import { Agent } from '../types';

const LOGROCKET = {
  APP: '2ihjlg',
  NAME: 'LogRocket',
  ID: {
    [ENVIRONMENT.SNP]: 'coverforce-agent-portal',
    [ENVIRONMENT.PROD]: 'agent-portal-production-bzv9e',
  },
};

export const initialiseLogRocket = () => {
  LogRocket.init(`${LOGROCKET.APP}/${LOGROCKET.ID[REACT_APP_ENV as string]}`);
};

export const setLogRocketUser = (currentAgent: Agent) => {
  LogRocket.identify(currentAgent?.agentId, {
    name: `${currentAgent?.firstName ?? ''} ${currentAgent?.lastName ?? ''}`.trim(),
    email: currentAgent?.email,
  });
};

export const integrateLogRocketWithSentry = () => {
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.getCurrentScope().setExtra('sessionURL', sessionURL);
  });
};

export const integrateLogRocketWithGA = () => {
  LogRocket.getSessionURL((sessionURL) => {
    ReactGa.send({
      hitType: 'event',
      eventCategory: LOGROCKET.NAME,
      eventAction: sessionURL,
    });
  });
};
