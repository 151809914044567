import { IAddAgencyNetworkMetadataInput, IAgencyNetworkMetadata } from '@coverforce-platform/cf-common-api-model';
import { AgentPortalUserType } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getAgencyNetworkMetadata, updateAgencyNetworkMetadata } from '../../externalServices/V2/agencyNetwork';
import { getAllPolicyTypes } from '../../externalServices/V2/carrierRankingV2';
import { getAllCarriersInfo } from '../../externalServices/V2/carriersV2';
import { getThemeByThemeName } from '../../externalServices/V2/themeService';
import { useProfileV2Store } from '../../pages/v2/profile/store';
import { ITheme } from '../../types';
import { CFTheme } from '../../ui-core/themes/CFTheme';
import { ACTION_TYPE } from './constants';
import { IConfigurationStore } from './interface';

export const useConfigurationStore = create<IConfigurationStore>()(
  devtools(
    (set) => ({
      isConfigurationLoading: true,
      policyTypes: {},
      policyTypesLoading: false,
      policyTypesError: null,
      allCarriers: {},
      allCarriersLoading: false,
      allCarriersError: null,
      userTheme: CFTheme,
      userType: AgentPortalUserType.AGENCY, // Treating default/fallback userType as AGENCY
      agencyNetworkMetadata: undefined,
      agencyNetworkMetadataLoading: false,
      webAppThemeAPI: undefined,
      webAppThemeAPILoading: false,
      feedClient: undefined,

      setUserType: (userType: AgentPortalUserType) => {
        set({ userType }, false, ACTION_TYPE.SET_PROFILE_USER_TYPE);
      },

      setUserTheme: (userTheme: ITheme) => set({ userTheme }, false, ACTION_TYPE.SET_USER_THEME),

      getPolicyTypes: async (loading = true) => {
        try {
          set({ policyTypesLoading: loading }, false, ACTION_TYPE.POLICY_TYPES_LOADING_START);
          const policyTypes: { [k: string]: string } = {};
          const response = await getAllPolicyTypes();
          response.policyTypes?.forEach((policyType) => {
            policyTypes[policyType.policyTypeId] = policyType.policyTypeDisplayName;
          });
          set({ policyTypes, policyTypesError: null }, false, ACTION_TYPE.FETCHED_POLICY_TYPES);
        } catch (err: any) {
          set({ policyTypes: {}, policyTypesError: err }, false, ACTION_TYPE.POLICY_TYPES_ERROR);
        } finally {
          set({ policyTypesLoading: false }, false, ACTION_TYPE.POLICY_TYPES_LOADING_STOP);
        }
      },

      getAllCarriers: async (loading = true) => {
        try {
          set({ allCarriersLoading: loading }, false, ACTION_TYPE.ALL_CARRIERS_LOADING_START);
          const allCarriers: { [k: string]: string } = {};
          const response = await getAllCarriersInfo();
          response.carriers?.forEach((carrier) => {
            allCarriers[carrier.carrierId] = carrier.carrierDisplayName;
          });
          set({ allCarriers, allCarriersError: null }, false, ACTION_TYPE.FETCHED_ALL_CARRIERS);
        } catch (err: any) {
          set({ allCarriers: {}, allCarriersError: err }, false, ACTION_TYPE.ALL_CARRIERS_ERROR);
        } finally {
          set({ allCarriersLoading: false }, false, ACTION_TYPE.ALL_CARRIERS_LOADING_STOP);
        }
      },

      getAgencyNetworkMetadata: async (loading = true) => {
        const { profileData } = useProfileV2Store.getState();
        try {
          set(
            { agencyNetworkMetadataLoading: loading },
            false,
            ACTION_TYPE.FETCHING_AGENCY_NETWORK_METADATA_LOADING_START,
          );
          const response = (await getAgencyNetworkMetadata({
            agencyNetworkId: profileData?.agencyNetworkId ?? '',
          })) as IAgencyNetworkMetadata;
          set({ agencyNetworkMetadata: response }, false, ACTION_TYPE.FETCHED_AGENCY_NETWORK_METADATA);
          return response;
        } catch (error) {
          set({ agencyNetworkMetadata: undefined }, false, ACTION_TYPE.AGENCY_NETWORK_METADATA_ERROR);
          return undefined;
        } finally {
          set(
            { agencyNetworkMetadataLoading: false },
            false,
            ACTION_TYPE.FETCHING_AGENCY_NETWORK_METADATA_LOADING_STOP,
          );
        }
      },

      updateAgencyNetworkMetadata: async (payload, loading = true) => {
        set(
          { agencyNetworkMetadataLoading: loading },
          false,
          ACTION_TYPE.FETCHING_AGENCY_NETWORK_METADATA_LOADING_START,
        );
        const response = await updateAgencyNetworkMetadata(payload as IAddAgencyNetworkMetadataInput);
        set(
          { agencyNetworkMetadata: response, agencyNetworkMetadataLoading: false },
          false,
          ACTION_TYPE.FETCHED_AGENCY_NETWORK_METADATA,
        );

        return response;
      },

      getThemeFromAPI: async (themeName = CFTheme.themeName, loading = true) => {
        try {
          set({ webAppThemeAPILoading: loading }, false, ACTION_TYPE.WEBAPP_THEME_API_LOADING_START);
          const response = await getThemeByThemeName({ themeName });
          set({ webAppThemeAPI: response }, false, ACTION_TYPE.WEBAPP_THEME_API);
          return response;
        } catch (error) {
          set({ webAppThemeAPI: undefined }, false, ACTION_TYPE.WEBAPP_THEME_API_ERROR);
          return undefined;
        } finally {
          set({ webAppThemeAPILoading: false }, false, ACTION_TYPE.WEBAPP_THEME_API_LOADING_STOP);
        }
      },

      updateConfigurationByKey: async (storeKey: keyof IConfigurationStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, ACTION_TYPE.UPDATE_CONFIGURATION_STORE_BY_KEY),

      clearConfiguration: () =>
        set(
          {
            isConfigurationLoading: true,
            policyTypes: {},
            policyTypesLoading: false,
            policyTypesError: null,
            allCarriers: {},
            allCarriersLoading: false,
            allCarriersError: null,
            agencyNetworkMetadata: undefined,
            agencyNetworkMetadataLoading: false,
            webAppThemeAPI: undefined,
            webAppThemeAPILoading: false,
            feedClient: undefined,
          },
          false,
          ACTION_TYPE.CLEAR_CONFIGURATION,
        ),
    }),
    { anonymousActionType: 'configuration', name: 'Configuration Store' },
  ),
);
