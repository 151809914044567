import { IdcardOutlined, LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import Anchor from '../../../ui-core/V2/anchor/anchor';
import { CFMenuItem } from '../../../ui-core/V2/cfMenu/cfMenu';
import { redirectToLogout } from '../../../utils/redirectToLogout';
import { CFNavMenuStyled } from '../../navBarV2/styles';
import { USER_MENU_NAVIGATION_PATHS } from './constants';

const UserMenu = () => {
  const navigate = useNavigate();
  const theme: any = useTheme();

  const onClickNavigate = (to: string) => {
    switch (to) {
      case USER_MENU_NAVIGATION_PATHS.LOGOUT: {
        redirectToLogout({ redirectToHome: true });
        break;
      }
      default: {
        navigate(to);
        break;
      }
    }
  };

  return (
    <CFNavMenuStyled
      id='nav_menu_user'
      onClick={(info) => onClickNavigate(info.key)}
      backgroundColor={theme.colors.grayscale.white}
    >
      <CFMenuItem icon={<IdcardOutlined />} key={USER_MENU_NAVIGATION_PATHS.PROFILE}>
        <Anchor id='nav_profiler' href={USER_MENU_NAVIGATION_PATHS.PROFILE}>
          Profile
        </Anchor>
      </CFMenuItem>
      <CFMenuItem icon={<LogoutOutlined />} key={USER_MENU_NAVIGATION_PATHS.LOGOUT} danger>
        Logout
      </CFMenuItem>
    </CFNavMenuStyled>
  );
};

export default UserMenu;
