import {
  IAccountInfo,
  IAuthenticateAndOnboardSSOJwtUserInput,
  ICreateAccountInput,
  IDeleteAccountInput,
  IGetAccountInput,
  IGetAccountsInput,
  IGetAccountsOutput,
  IGetPublicAPIClientInfoByAgencyInput,
  IGetPublicAPIClientInfoByAgencyNetworkInput,
  IGetPublicAPIClientInfoByAgencyNetworkOutput,
  IGetPublicAPIClientInfoByAgencyOutput,
  IGetPublicAPIClientInfoInput,
  IGetPublicAPIClientInfoOutput,
  IRegisterAgentInput,
  ISearchAccountsInput,
  ISearchAccountsOutput,
  ISSOOutput,
  IUpdateAccountInput,
} from '@coverforce-platform/cf-common-api-model';
import { AxiosResponse } from 'axios';

import axiosAccountService from '../../axios/accountService/instance';
import { ACCOUNT_SERVICE_ENDPOINTS } from '../../externalServices/helpers/ApiRoutes';
import { createQueryUrl } from '../../utils/createQueryUrl';

const getAccounts = async (params: IGetAccountsInput): Promise<IGetAccountsOutput> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.GET_ACCOUNTS(),
    params,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const addNewAccount = async (params: ICreateAccountInput) => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.ADD_ACCOUNT,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const updateAccount = async (params: IUpdateAccountInput) => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.UPDATE_ACCOUNT,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.put(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const deleteAccount = async (params: IDeleteAccountInput) => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.DELETE_ACCOUNT(params.accountId),
  });

  try {
    const response: AxiosResponse = await axiosAccountService.delete(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getAccountByAccountId = async (params: IGetAccountInput): Promise<IAccountInfo> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.GET_ACCOUNT_BY_ACCOUNT_ID(params.accountId),
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getAccountsBySearch = async (params: ISearchAccountsInput): Promise<ISearchAccountsOutput> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.ACCOUNTS_SEARCH,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const sendRegistrationData = async (params: IRegisterAgentInput): Promise<void> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.REGISTER_AGENT,
  });
  try {
    await axiosAccountService.post(apiUrl, params, { customConfig: { isUnauthenticated: true } });
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getSsoToken = async (params: IAuthenticateAndOnboardSSOJwtUserInput): Promise<ISSOOutput> => {
  const getSsoTokenApiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.GET_SSO_TOKEN,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.post(getSsoTokenApiUrl, params, {
      customConfig: { isUnauthenticated: true },
    });
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getPublicApiClientInfo = async (params: IGetPublicAPIClientInfoInput): Promise<IGetPublicAPIClientInfoOutput> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.GET_PUBLIC_API_CLIENT_INFO(params),
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getPublicApiClientsByAgencyNetwork = async (
  params: IGetPublicAPIClientInfoByAgencyNetworkInput,
): Promise<IGetPublicAPIClientInfoByAgencyNetworkOutput> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.GET_PUBLIC_API_CLIENTS_BY_AGENCY_NETWORK(params),
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getPublicApiClientsByAgency = async (
  params: IGetPublicAPIClientInfoByAgencyInput,
): Promise<IGetPublicAPIClientInfoByAgencyOutput> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.GET_PUBLIC_API_CLIENTS_BY_AGENCY(params),
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export {
  getAccounts,
  addNewAccount,
  updateAccount,
  deleteAccount,
  getAccountByAccountId,
  getAccountsBySearch,
  sendRegistrationData,
  getSsoToken,
  getPublicApiClientInfo,
  getPublicApiClientsByAgency,
  getPublicApiClientsByAgencyNetwork,
};
