import { IDeleteAccountInput } from '@coverforce-platform/cf-common-api-model';
import { AccountType } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { Address } from '../../../components/question/locationInput/interface';
import {
  addNewAccount,
  deleteAccount,
  getAccounts,
  updateAccount as requestUpdateAccount,
} from '../../../externalServices/V2/accountsV2';
import { DEFAULT_PAGINATION, DRAWER_TYPE, PAGE_SIZE } from '../../../globalConstants';
import { deNormalizePhoneNumber } from '../../../helpers/Utils';
import { useCFDrawerStore } from '../../../ui-core/V2/cfDrawer/store';
import { isAddressFilled } from '../../../ui-core/V2/cfFormDrawer/utils';
import { pushEvent } from '../../../utils/googleAnalytics';
import { IAccount } from '../account/interface';
import { useProfileV2Store } from '../profile/store';
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from './constant';
import { IAccountsData, IAccountsStore, IAddAccount } from './interface';
import { getFormattedAccountParams } from './utils';

export const useAccountsStore = create<IAccountsStore>()(
  devtools(
    (set, get) => ({
      loading: true,
      accounts: undefined,
      error: null,

      fetchAccounts: async ({ page = DEFAULT_PAGINATION.PAGE, size = PAGE_SIZE }) => {
        try {
          const res = await getAccounts({
            agencyId: useProfileV2Store.getState().profileData?.agencyId || '',
            page: page.toString(),
            size: size.toString(),
          });

          set({ accounts: res.accounts, error: null, loading: false }, false, 'fetchAccounts');
        } catch (error: any) {
          set({ accounts: undefined, error, loading: false }, false, 'fetchAccountsError');
        }
      },

      addAccount: async (account: IAddAccount) => {
        const newAccount: IAccount = {
          ...account,
          accountType: AccountType.BUSINESS,
        };
        const createdAccount = await addNewAccount(
          getFormattedAccountParams({
            ...newAccount,
            agencyId: useProfileV2Store.getState().profileData?.agencyId || '',
            primaryContactDetails: newAccount.primaryContactDetails && {
              ...newAccount.primaryContactDetails,
              phone:
                newAccount.primaryContactDetails.phone && newAccount.primaryContactDetails.phone !== ''
                  ? deNormalizePhoneNumber(newAccount.primaryContactDetails.phone)
                  : undefined,
            },
            primaryAddress: isAddressFilled(newAccount.primaryAddress as Address)
              ? newAccount.primaryAddress
              : undefined,
          }),
        );
        return createdAccount;
      },

      updateAccount: async (updatedAccount, shouldFetch = true) => {
        await requestUpdateAccount(
          getFormattedAccountParams({
            ...updatedAccount,
            primaryContactDetails: updatedAccount.primaryContactDetails && {
              ...updatedAccount.primaryContactDetails,
              phone:
                updatedAccount.primaryContactDetails.phone && updatedAccount.primaryContactDetails.phone !== ''
                  ? deNormalizePhoneNumber(updatedAccount.primaryContactDetails.phone)
                  : undefined,
            },
            primaryAddress: isAddressFilled(updatedAccount.primaryAddress as Address)
              ? updatedAccount.primaryAddress
              : undefined,
          }),
        );
        if (shouldFetch) {
          get().fetchAccounts({});
        }
      },

      removeAccount: async (params: IDeleteAccountInput, shouldFetch = true) => {
        await deleteAccount(params);
        if (shouldFetch) {
          get().fetchAccounts({});
        }
      },

      clearAccounts: () => set(() => ({ accounts: undefined, error: null, loading: true }), false, 'clearAccounts'),

      updateAccountsByKey: (storeKey: keyof IAccountsData, value: any) =>
        set(() => ({ [storeKey]: value }), false, 'updateAccountsByKey'),

      openNewAccountDrawer: () => {
        const { setCFDrawerData } = useCFDrawerStore.getState();
        setCFDrawerData({ isVisible: true, drawerType: DRAWER_TYPE.ACCOUNT_ADD });
        pushEvent({
          category: GA_CATEGORY.ACCOUNTS_HEADER,
          action: GA_ACTION.ADD_NEW_ACCOUNT_BTN_CLICK,
          label: GA_LABEL.NEW_ACCOUNT,
        });
      },
    }),
    { anonymousActionType: 'accounts' },
  ),
);
