import { BurglarAlarmSystemCode, ConstructionTypeCode, RoofTypeCode } from '@coverforce-platform/cf-common-types';
import dayjs from 'dayjs';

import { ANTD_QUESTION_TYPE } from '../applicationQuestion/constants';
import { IBopQuestion } from '../bopBusinessQuestion/interface';
import { IBuildingInformationStore } from './interface';

export const BUILDING_CONSTRUCTION_FIELDS = [
  { label: 'Frame', value: ConstructionTypeCode.FRAME },
  { label: 'Joisted Masonry', value: ConstructionTypeCode.JOISTED_MASONRY },
  { label: 'Non Combustible', value: ConstructionTypeCode.NON_COMBUSTIBLE },
  { label: 'Masonry Non Combustible', value: ConstructionTypeCode.MASONRY_NON_COMBUSTIBLE },
  { label: 'Fire Resistive', value: ConstructionTypeCode.FIRE_RESISTIVE },
  { label: 'Modified Fire Resistive', value: ConstructionTypeCode.MODIFIED_FIRE_RESISTIVE },
];

export const ROOF_CONSTRUCTION_FIELDS = [
  { label: 'Asphalt Shingles', value: RoofTypeCode.ASPHALT_SHINGLES },
  { label: 'Built-up with Gravel', value: RoofTypeCode.BUILT_UP_GRAVEL },
  { label: 'Built-up without Gravel', value: RoofTypeCode.BUILT_UP_WITHOUT_GRAVEL },
  { label: 'Clay Tiles', value: RoofTypeCode.CLAY_TILES },
  { label: 'Metal', value: RoofTypeCode.METAL },
  { label: 'Foam', value: RoofTypeCode.FOAM },
  { label: 'Modified Bitumen', value: RoofTypeCode.MODIFIED_BITUMEN },
  { label: 'Single Ply Ballasted', value: RoofTypeCode.SINGLE_PLY_BALLASTED },
  { label: 'Single Ply SPDM Rubber', value: RoofTypeCode.SINGLE_PLY_SPDM_RUBBER },
  { label: 'Single Ply PVC TPO', value: RoofTypeCode.SINGLE_PLY_PVC_TPO },
  { label: 'Wood Shingles', value: RoofTypeCode.WOOD_SHINGLES },
];

export const BURGER_ALARM_FIELDS = [
  { label: 'Central Station Alarm System', value: BurglarAlarmSystemCode.BURGLAR_CENTRAL },
  { label: 'Police/Fire Connected Alarm System', value: BurglarAlarmSystemCode.BURGLAR_POLICE_FIRE },
  { label: 'Local Alarm System', value: BurglarAlarmSystemCode.BURGLAR_LOCAL },
  { label: 'No Alarm System', value: BurglarAlarmSystemCode.BURGLAR_NONE },
];

const emptyFields = [undefined, ''];

export const QUESTION_KEYS = {
  sqFootageUnOccupied: 'areaOccupiedByOthers',
  sqFootageOccupied: 'areaOccupied',
  totalSquareFootage: 'totalArea',
  yearBuilt: 'yearBuilt',
  roofUpdateYear: 'roofUpdateYear',
  electricalWiringUpdateYear: 'electricalWiringUpdateYear',
  plumbingUpdateYear: 'plumbingUpdateYear',
  heatingUpdateYear: 'heatingUpdateYear',
  isOwner: 'isOwner',
  buildingCoverage: 'buildingCoverage',
  businessPersonalPropertyCoverage: 'businessPersonalPropertyCoverage',
  buildingCoverageClassId: 'buildingCoverageClassId',
  businessPersonalPropertyCoverageClassId: 'businessPersonalPropertyCoverageClassId',
};

export const ERROR_MESSAGES = {
  greaterThan0: 'Value should be greater than 0.',
  greaterThanOrEqual0: 'Value should be greater than or equal to 0.',
  totalMustBeGreaterThan: 'Total Area cannot be less than sum of Area Occupied and Unoccupied.',
  bppOrBuildingCoverageGreaterThan0: 'One of Business Personal Property or Building Coverage should be greater than 0.',
  oneAreaMustBeGreaterThan0: 'One of Total Area, Area Occupied or Area Unoccupied should be greater than 0.',
};

/**
 * For adding dependency question
 * Some example condition format
 *  [CURRENT_VALUE]<= ([CURRENT_YEAR])
 *  [CURRENT_VALUE] === New acquisition for the insured
 *  [CURRENT_VALUE]=== Other
 *  ([CURRENT_YEAR] - [CURRENT_VALUE]) >= 20
 */

export const BUILDING_INFORMATION_QUESTION: IBopQuestion[][] = [
  [
    {
      type: ANTD_QUESTION_TYPE.MONEY,
      name: 'Annual Sales',
      dataIndex: 'annualSales',
      tooltip: 'Current or expected annual sales',

      rules: [
        {
          required: true,
          validator: (_: any, value: any) => {
            if (!value) {
              return Promise.reject('Please enter Annual Sales.');
            }
            if (Number(value) <= 0) {
              return Promise.reject(ERROR_MESSAGES.greaterThan0);
            }
            return Promise.resolve();
          },
        },
      ],
    },
    {
      type: ANTD_QUESTION_TYPE.RADIO,
      name: 'Does the insured own the property?',
      dataIndex: 'isOwner',
      tooltip: 'This could include the entire building or a unit in it',

      options: [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ],
      rules: [{ required: true, message: 'Please select Yes or No.' }],
    },
  ],
  [
    {
      type: ANTD_QUESTION_TYPE.DROPDOWN,
      name: 'Building Construction Type',
      dataIndex: 'constructionTypeCode',
      placeholder: 'Choose the Building Construction Type',
      options: BUILDING_CONSTRUCTION_FIELDS,
      rules: [{ required: true, message: 'Please select Building Construction Type.' }],
    },
    {
      type: ANTD_QUESTION_TYPE.DROPDOWN,
      name: 'Roof Construction Type',
      dataIndex: 'roofType',
      placeholder: 'Choose the Roof Construction Type',
      options: ROOF_CONSTRUCTION_FIELDS,
      rules: [{ required: true, message: 'Please select Roof Construction Type.' }],
    },
  ],
  [
    {
      type: ANTD_QUESTION_TYPE.NUMBER,
      name: 'Total Area (sq ft)',
      dataIndex: 'totalArea',
      placeholder: 'Total Area (sq ft)',
      tooltip: ' Total Area of the location',
      rules: [
        {
          required: true,
          message: 'Please enter Total Area (sq ft).',
        },
        ({ getFieldValue }: { getFieldValue: Function }) => ({
          validator: (_: any, value: any) => {
            const totalSqFootage = Number(value || 0);
            const sqOccupiedFootage = Number(getFieldValue([QUESTION_KEYS.sqFootageOccupied]) || 0);
            const sqUnOccupiedFootage = Number(getFieldValue([QUESTION_KEYS.sqFootageUnOccupied]) || 0);

            if ((value || value === 0) && sqOccupiedFootage + sqUnOccupiedFootage + totalSqFootage <= 0) {
              return Promise.reject(ERROR_MESSAGES.oneAreaMustBeGreaterThan0);
            }
            if ((value || value === 0) && sqOccupiedFootage + sqUnOccupiedFootage > totalSqFootage) {
              return Promise.reject(ERROR_MESSAGES.totalMustBeGreaterThan);
            }

            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: ANTD_QUESTION_TYPE.NUMBER,
      name: 'Area Occupied (sq ft)',
      dataIndex: 'areaOccupied',
      placeholder: 'Area Occupied (sq ft)',
      tooltip: 'Area occupied by the business',
      rules: [
        {
          required: true,
          message: 'Please enter Area Occupied (sq ft).',
        },
        ({ getFieldValue }: { getFieldValue: Function }) => ({
          validator: (_: any, value: any) => {
            const sqOccupiedFootage = Number(value || 0);
            const totalSqFootage = Number(getFieldValue([QUESTION_KEYS.totalSquareFootage]) || 0);
            const sqUnOccupiedFootage = Number(getFieldValue([QUESTION_KEYS.sqFootageUnOccupied]) || 0);

            if ((value || value === 0) && sqOccupiedFootage + sqUnOccupiedFootage + totalSqFootage <= 0) {
              return Promise.reject(ERROR_MESSAGES.oneAreaMustBeGreaterThan0);
            }

            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: ANTD_QUESTION_TYPE.NUMBER,
      name: 'Area Occupied by Others (sq ft)',
      dataIndex: 'areaOccupiedByOthers',
      placeholder: 'Area Occupied by Others (sq ft)',
      tooltip: 'Area occupied by others in the location',
      rules: [
        {
          required: true,
          message: 'Please enter Area Occupied by Others (sq ft).',
        },
        ({ getFieldValue }: { getFieldValue: Function }) => ({
          validator: (_: any, value: any) => {
            const sqUnOccupiedFootage = Number(value || 0);
            const sqOccupiedFootage = Number(getFieldValue([QUESTION_KEYS.sqFootageOccupied]) || 0);
            const totalSqFootage = Number(getFieldValue([QUESTION_KEYS.totalSquareFootage]) || 0);

            if ((value || value === 0) && sqOccupiedFootage + sqUnOccupiedFootage + totalSqFootage <= 0) {
              return Promise.reject(ERROR_MESSAGES.oneAreaMustBeGreaterThan0);
            }

            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: ANTD_QUESTION_TYPE.NUMBER,
      name: 'Total Stories',
      dataIndex: 'totalStories',
      placeholder: 'Total Stories',
      rules: [
        {
          required: true,
          validator: (_: any, value: any) => {
            if (!value && value !== 0) {
              return Promise.reject('Please enter Total Stories.');
            }
            if (Number(value) <= 0) {
              return Promise.reject(ERROR_MESSAGES.greaterThan0);
            }
            return Promise.resolve();
          },
        },
      ],
    },
  ],
  [
    {
      type: ANTD_QUESTION_TYPE.DATE,
      subtype: ANTD_QUESTION_TYPE.YEAR,
      name: 'Year Built',
      dataIndex: 'yearBuilt',
      rules: [
        {
          required: true,
          message: 'Please enter Year Built.',
        },
      ],
      disabledDate: (year: dayjs.Dayjs) => year && year > dayjs().endOf('year'),
      condition: '([CURRENT_YEAR] - [CURRENT_VALUE]) >= 20',
      dependentQuestions: [
        {
          type: ANTD_QUESTION_TYPE.DATE,
          subtype: ANTD_QUESTION_TYPE.YEAR,
          name: 'Year Electrical Updated',
          dataIndex: 'electricalWiringUpdateYear',
          rules: [
            {
              required: true,
              message: 'Please enter Year Electrical Updated.',
            },
          ],
          disabled: true,
        },
        {
          type: ANTD_QUESTION_TYPE.DATE,
          subtype: ANTD_QUESTION_TYPE.YEAR,
          name: 'Year Plumbing Updated',
          dataIndex: 'plumbingUpdateYear',
          rules: [
            {
              required: true,
              message: 'Please enter Year Plumbing Updated.',
            },
          ],
          disabled: true,
        },
        {
          type: ANTD_QUESTION_TYPE.DATE,
          subtype: ANTD_QUESTION_TYPE.YEAR,
          name: 'Year Heating Updated',
          dataIndex: 'heatingUpdateYear',
          rules: [
            {
              required: true,
              message: 'Please enter Year Heating Updated.',
            },
          ],
          disabled: true,
        },
      ],
    },
    {
      type: ANTD_QUESTION_TYPE.DATE,
      subtype: ANTD_QUESTION_TYPE.YEAR,
      name: 'Year Roof Updated',
      dataIndex: 'roofUpdateYear',
      disabled: true,
      rules: [
        {
          required: true,
          message: 'Please enter Year Roof Updated.',
        },
      ],
    },
  ],
  [
    {
      type: ANTD_QUESTION_TYPE.NUMBER,
      name: 'Percentage Building Sprinklered',
      dataIndex: 'sprinkleredPercentage',
      placeholder: 'Percentage Building Sprinklered',
      rules: [
        {
          required: true,
          validator: (_: any, value: any) => {
            if (!value && value !== 0) {
              return Promise.reject(new Error('Please enter Percentage Building Sprinklered.'));
            }
            const numberValue = Number(value);
            if (numberValue >= 0 && numberValue <= 100) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('Please enter a value between 0 and 100.'));
          },
        },
      ],
    },
    {
      type: ANTD_QUESTION_TYPE.DROPDOWN,
      name: 'Burglar Alarm Type',
      dataIndex: 'burglarAlarmTypeCode',
      placeholder: 'Choose the Burglar Alarm Type',
      options: BURGER_ALARM_FIELDS,
      rules: [{ required: true, message: 'Please select Burglar Alarm Type.' }],
    },
  ],
  [
    {
      type: ANTD_QUESTION_TYPE.MONEY,
      name: 'Building Coverage',
      dataIndex: 'buildingCoverage',
      rules: [
        {
          required: true,
          message: 'Please enter Building Coverage.',
        },
        ({ getFieldValue }: { getFieldValue: Function }) => ({
          validator: (name: any, value: any) => {
            const isOwner = getFieldValue(QUESTION_KEYS.isOwner);
            const businessPersonalPropertyCoverage = getFieldValue(QUESTION_KEYS.businessPersonalPropertyCoverage);
            const businessPersonalPropertyCoverageValue = Number(businessPersonalPropertyCoverage) || 0;
            if (typeof isOwner === 'string') {
              if (isOwner === 'false') {
                if (Number(value) < 0 && !emptyFields.includes(value)) {
                  return Promise.reject(ERROR_MESSAGES.greaterThan0);
                }
              }
              if (isOwner === 'true') {
                if (
                  !emptyFields.includes(value) &&
                  !emptyFields.includes(businessPersonalPropertyCoverage) &&
                  businessPersonalPropertyCoverageValue <= 0 &&
                  Number(value) <= 0
                ) {
                  return Promise.reject(ERROR_MESSAGES.bppOrBuildingCoverageGreaterThan0);
                }
              }
            }

            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: ANTD_QUESTION_TYPE.DROPDOWN,
      name: 'Liberty Mutual CSP code',
      dataIndex: 'buildingCoverageClassId',
      placeholder: 'Liberty Mutual CSP code',
      options: [],
      colSpan: 6,
      isHidden: true,
      rules: [{ required: true, message: 'Please select Liberty Mutual CSP code.' }],
    },
    {
      type: ANTD_QUESTION_TYPE.MONEY,
      name: 'Business Personal Property Coverage',
      dataIndex: 'businessPersonalPropertyCoverage',
      rules: [
        {
          required: true,
          message: 'Please enter Business Personal Property Coverage.',
        },
        ({ getFieldValue }: { getFieldValue: Function }) => ({
          validator: (name: any, value: any) => {
            const isOwner = getFieldValue(QUESTION_KEYS.isOwner);
            const buildingCoverage = getFieldValue(QUESTION_KEYS.buildingCoverage);
            const buildingCoverageValue = Number(buildingCoverage) || 0;
            if (typeof isOwner === 'string') {
              if (isOwner === 'false') {
                if (Number(value) <= 0 && !emptyFields.includes(value)) {
                  return Promise.reject(ERROR_MESSAGES.greaterThan0);
                }
              }
              if (isOwner === 'true') {
                if (
                  !emptyFields.includes(value) &&
                  !emptyFields.includes(buildingCoverage) &&
                  buildingCoverageValue <= 0 &&
                  Number(value) <= 0
                ) {
                  return Promise.reject(ERROR_MESSAGES.bppOrBuildingCoverageGreaterThan0);
                }
              }
            }

            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: ANTD_QUESTION_TYPE.DROPDOWN,
      name: 'Liberty Mutual CSP code',
      dataIndex: 'businessPersonalPropertyCoverageClassId',
      placeholder: 'Liberty Mutual CSP code',
      options: [],
      colSpan: 6,
      isHidden: true,
      rules: [{ required: true, message: 'Please select Liberty Mutual CSP code.' }],
    },
  ],
];

export const BUILDING_STORE_META_DATA = {
  NAME: 'Building Information Store',
  ANONYMOUS_ACTION: 'BUILDING_INFORMATION_ACTIONS',
  UPDATE_BUILDING_INFORMATION_STORE_BY_KEY: 'UPDATE_BUILDING_INFORMATION_STORE_BY_KEY',
  SET_CSP_OPTIONS_SUCCESS: 'SET_CSP_OPTIONS_SUCCESS',
  SET_CSP_OPTIONS_ERROR: 'SET_CSP_OPTIONS_ERROR',
  CLEAR_BUILDING_INFORMATION: 'CLEAR_BUILDING_INFORMATION',
};

export const BUILDING_STORE_KEYS: { [key: string]: keyof IBuildingInformationStore } = {
  BUILDING_INFORMATION_QUESTIONS: 'buildingInformationQuestions',
};
