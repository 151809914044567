import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getAgentByUsername, updateAgent } from '../../../externalServices/V2/agents';
import { Agent } from '../../../types';
import { error } from '../../../ui-core/Notification';
import { getUpdatedUserPayload } from '../../../utils/teamManagement/team';
import { IProfileV2Data, IProfileV2Store } from './interface';

export const useProfileV2Store = create<IProfileV2Store>()(
  devtools(
    (set, get) => ({
      authLoading: true,
      profileLoading: false,
      profileData: undefined,
      profileError: null,

      fetchProfile: async (isEdit = false) => {
        try {
          set({ profileLoading: true });
          const profileData = await getAgentByUsername();
          set({ profileData: profileData?.agents?.[0], profileError: null }, false, 'fetchProfile');
        } catch (err: any) {
          if (isEdit) {
            set({ profileError: err }, false, 'fetchProfileError');
            error(err?.[0]?.errorMessage);
          } else {
            set({ profileData: undefined, profileError: err }, false, 'fetchProfileError');
          }
        } finally {
          set({ authLoading: false, profileLoading: false }, false, 'fetchProfileLoading');
        }
      },

      clearProfile: () =>
        set(
          { profileData: undefined, profileError: null, authLoading: true, profileLoading: false },
          false,
          'clearProfile',
        ),

      updateProfileByKey: (storeKey: keyof IProfileV2Data, value: any) =>
        set(() => ({ [storeKey]: value }), false, 'updateProfileByKey'),

      updateProfile: async (updatedAgent: Agent) => {
        const updateAgentPayload = getUpdatedUserPayload(updatedAgent);

        await updateAgent(updateAgentPayload);
        get().fetchProfile(true);
      },
    }),
    { anonymousActionType: 'profileV2' },
  ),
);
