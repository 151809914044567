/* eslint-disable @typescript-eslint/no-unused-vars */
import { Axios, AxiosResponse } from 'axios';

import { AUTH0_GET_ACCESS_TOKEN_SILENTLY_PARAMS, HTTPS_CODES, JWT_TOKEN } from '../../globalConstants';
import { getAuth0TokenSilently } from '../../utils/auth0';
import { parseApiError } from '../../utils/parseApiError';
import { redirectToLogout } from '../../utils/redirectToLogout';
import { setAuthTokens } from '../../utils/setAuthTokens';
// import { isDevEnvironment } from '../../utils/isDevEnvironment';
import { getRequestHeaders } from '../utils/getRequestHeaders';
import { IAxiosRequestConfig } from './interface';

// Intercept https request here
export const defaultRequestInterceptor = async ({
  axiosInstance,
  serviceName,
}: {
  axiosInstance: Axios;
  serviceName: string;
}) =>
  await axiosInstance.interceptors.request.use(
    // TO-DO:- Revert to strongly typed approach and remove "any"
    // async (config: IAxiosRequestConfig): Promise<IAxiosRequestConfig> => {
    async (config: any): Promise<any> => {
      config.headers = getRequestHeaders(config);
      config = await getInterceptedRequestConfig(config);
      // isDevEnvironment() && console.log(`axios ${serviceName} default request interceptor config`, config);
      return config;
    },
    (error): any => {
      // isDevEnvironment() && console.log(`axios ${serviceName} default request interceptor error`, error);
      throw new Error(error);
    },
  );

// Intercept https response here
export const defaultResponseInterceptor = async ({
  axiosInstance,
  serviceName,
}: {
  axiosInstance: Axios;
  serviceName: string;
}) =>
  await axiosInstance.interceptors.response.use(
    (response): AxiosResponse => {
      // console.log(`axios ${serviceName} default response interceptor`, response);
      return response;
    },
    async (error: any) => {
      // isDevEnvironment() && console.log(`axios ${serviceName} default response interceptor error`, error);
      // Add middleware logic here like 401 handling
      if (error?.response?.status === HTTPS_CODES.UNAUTHENTICATED && !error?.config?.customConfig?.isUnauthenticated) {
        const refreshedTokenResponse = await refreshAccessToken(axiosInstance, error);
        return refreshedTokenResponse;
      } else if (error?.config?.customConfig?.retryCount && error?.config?.customConfig?.retryCount > 0) {
        error.config.customConfig.retryCount--;
        try {
          const refreshedResponse = await axiosInstance.request(error.config);
          // this is intended to break the loop if retry response is successful.
          error.config.customConfig.retryCount = 0;
          return refreshedResponse;
        } catch (refreshRequestError) {
          return Promise.reject(parseApiError(refreshRequestError));
        }
      } else {
        return Promise.reject(parseApiError(error));
      }
    },
  );

const getInterceptedRequestConfig = async (config: IAxiosRequestConfig): Promise<IAxiosRequestConfig> => {
  if (config?.customConfig?.isUnauthenticated) {
    config.baseURL = config?.baseURL?.replace('/api', '/public/api');
    delete config?.headers?.authorization;
  }
  return config;
};

const refreshAccessToken = async (axiosInstance: Axios, error: any) => {
  try {
    const tokenResponse = await getAuth0TokenSilently(AUTH0_GET_ACCESS_TOKEN_SILENTLY_PARAMS);
    await setAuthTokens(tokenResponse);
    const newAccessToken = tokenResponse[JWT_TOKEN.ACCESS_TOKEN];
    const existingAccessToken = (error.config?.headers?.authorization || '').replace('Bearer ', '');
    // If new token received and it's not same as existing access token, retry the original failed request
    if (newAccessToken && newAccessToken !== existingAccessToken) {
      error.config.headers.authorization = `Bearer ${newAccessToken}`;
      try {
        const refreshedResponse = await axiosInstance.request(error.config);
        return refreshedResponse;
      } catch (refreshRequestError) {
        return Promise.reject(parseApiError(refreshRequestError));
      }
    } else {
      redirectToLogout();
    }
  } catch (err) {
    redirectToLogout();
  }
};
