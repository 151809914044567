import { IRequestReportInput } from '@coverforce-platform/cf-common-api-model';
import { AgentPortalUserType } from '@coverforce-platform/cf-common-types';
import { cloneDeep } from 'lodash';

import { DATE_FORMAT } from '../../globalConstants';
import { useProfileV2Store } from '../../pages/v2/profile/store';
import { DROPDOWN_MODE } from '../../ui-core/V2/cfSelect/constants';
import { useConfigurationStore } from '../configuration/store';
import { IScheduleReportQuestion } from '../scheduleReport/interface';
import {
  DEFAULT_DATE_TIME_FRAME,
  GENERATE_REPORT_QUESTION_KEYS,
  GENERATE_REPORT_STORE_KEYS,
  REPORT_DURATION_CUSTOM_OPTION,
} from './constants';
import { IGenerateReportQuestion } from './interface';
import { useGenerateReportStore } from './store';

export const handleGenerateReportFieldChange = (question: IGenerateReportQuestion) => {
  return question.mode === DROPDOWN_MODE.TAGS
    ? {
        onMultiModeChange: undefined,
      }
    : {
        onChange: handleFieldOnChange,
      };
};

export const handleFieldOnChange = (entry: any, data: any) => {
  const { generateReportElements, generateReportForm, updateGenerateReportStoreByKey } =
    useGenerateReportStore.getState();
  const generateReportElementsClone = cloneDeep(generateReportElements);

  switch (data?.dataIndex) {
    case GENERATE_REPORT_QUESTION_KEYS.REPORT_DURATION:
      if (entry === REPORT_DURATION_CUSTOM_OPTION.value) {
        generateReportElementsClone?.forEach((element: IScheduleReportQuestion) => {
          if (element?.dataIndex === GENERATE_REPORT_QUESTION_KEYS.TIME_FRAME_DATE_RANGE) {
            element.hidden = false;
            element.rules = [{ required: true, message: 'Please enter Report time frame Start and End Date.' }];
          }
        });
      } else {
        generateReportElementsClone?.forEach((element: IScheduleReportQuestion) => {
          if (element?.dataIndex === GENERATE_REPORT_QUESTION_KEYS.TIME_FRAME_DATE_RANGE) {
            element.hidden = true;
            element.rules = undefined;
          }
        });
      }

      updateGenerateReportStoreByKey(GENERATE_REPORT_STORE_KEYS.GENERATE_REPORT_ELEMENTS, generateReportElementsClone);
      break;

    case GENERATE_REPORT_QUESTION_KEYS.IS_REPORT_DOWNLOAD:
      generateReportForm?.setFieldValue(GENERATE_REPORT_QUESTION_KEYS.IS_REPORT_DOWNLOAD, entry?.target?.checked);
      break;

    default:
      break;
  }
};

export const getGenerateReportApiPayloadUtil = () => {
  const { generateReportForm } = useGenerateReportStore.getState();
  const { userType } = useConfigurationStore.getState();
  const generateReportFormAnswers = generateReportForm?.getFieldsValue();
  const { profileData } = useProfileV2Store.getState();
  const payload: IRequestReportInput = {
    agencyNetworkId: profileData?.agencyNetworkId ?? '',
    agencyId: userType === AgentPortalUserType.AGENCY ? profileData?.agencyId ?? '' : undefined,
    reportTimeFrame: {
      reportDuration:
        generateReportFormAnswers?.reportDuration !== REPORT_DURATION_CUSTOM_OPTION.value
          ? generateReportFormAnswers?.reportDuration
          : undefined,
      startDateTime:
        generateReportFormAnswers?.reportDuration === REPORT_DURATION_CUSTOM_OPTION.value
          ? `${generateReportFormAnswers?.timeFrameDateRange?.[0]?.format(
              DATE_FORMAT.YYYYMMDD,
            )} ${DEFAULT_DATE_TIME_FRAME}`
          : undefined,
      endDateTime:
        generateReportFormAnswers?.reportDuration === REPORT_DURATION_CUSTOM_OPTION.value
          ? `${generateReportFormAnswers?.timeFrameDateRange?.[1]?.format(
              DATE_FORMAT.YYYYMMDD,
            )} ${DEFAULT_DATE_TIME_FRAME}`
          : undefined,
    },
    reportType: generateReportFormAnswers?.reportType,
    recipientEmailIds: { toEmailList: generateReportFormAnswers?.toEmailList },
    isRequestEmailOnly: !generateReportFormAnswers?.isReportDownload,
  };

  return payload;
};
