import { AcordFormType, IAcordDownloadTransactionId } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { downloadAcord, requestAcord } from '../../../externalServices/V2/acord';
import { useApplicationStore } from '../../../pages/v2/application/store';
import { useProfileV2Store } from '../../../pages/v2/profile/store';
import { DOWNLOAD_ACORD_METADATA } from './constants';
import { IDownloadAcordStore } from './interface';

export const useDownloadAcordStore = create<IDownloadAcordStore>()(
  devtools(
    () => ({
      requestAcord: async (requestedAcordFormsType: AcordFormType[]) => {
        const { profileData } = useProfileV2Store.getState();
        const { applicationData } = useApplicationStore.getState();

        const response = await requestAcord({
          applicationId: applicationData?.applicationId ?? '',
          requestedAcordFormsType,
          emailTo: [profileData?.email ?? ''],
          downloadRequestedBy: profileData?.agentId,
        });
        return response;
      },

      downloadAcord: async (acordDownloadTransactionId: IAcordDownloadTransactionId) => {
        const { applicationData } = useApplicationStore.getState();

        const response = await downloadAcord({
          applicationId: applicationData?.applicationId ?? '',
          acordDownloadTransactionId,
        });
        return response;
      },
    }),

    { anonymousActionType: DOWNLOAD_ACORD_METADATA.ANONYMOUS_ACTION, name: DOWNLOAD_ACORD_METADATA.STORE_NAME },
  ),
);
