import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { requestToBindQuote as requestToBindQuoteRequest } from '../../externalServices/V2/quotes';
import { useProfileV2Store } from '../../pages/v2/profile/store';
import { useQuotesStore } from '../quotes/store';
import { REQUEST_TO_BIND_FORM_ITEM, REQUEST_TO_BIND_MODAL_STORE } from './constants';
import { IRequestToBindStore } from './interface';

export const useRequestToBindQuoteModalStore = create<IRequestToBindStore>()(
  devtools(
    (set, get) => ({
      isRequestToBindLoading: false,
      requestToBindForm: undefined,

      requestToBindQuote: async (isRequestToBindLoading = true) => {
        set({ isRequestToBindLoading }, false, REQUEST_TO_BIND_MODAL_STORE.REQUEST_TO_BIND_LOADING);

        const { requestToBindForm } = get();
        const { selectedQuote } = useQuotesStore.getState();
        const { profileData } = useProfileV2Store.getState();

        const requestToBindNote = requestToBindForm?.getFieldValue(REQUEST_TO_BIND_FORM_ITEM.NOTE)?.trim() || '';

        const requestToBindQuoteResponse = await requestToBindQuoteRequest({
          applicationId: selectedQuote?.applicationId || '',
          cfQuoteId: selectedQuote?.cfQuoteId || '',
          agentId: profileData?.agentId || '',
          requestToBindNote,
        });
        return requestToBindQuoteResponse;
      },

      updateRequestToBindModalByKey: async (storeKey: keyof IRequestToBindStore, value: any) =>
        set(
          () => ({ [storeKey]: value }),
          false,
          REQUEST_TO_BIND_MODAL_STORE.UPDATE_REQUEST_TO_BIND_MODAL_STORE_BY_KEY,
        ),

      clearRequestToBind: () =>
        set({ isRequestToBindLoading: false }, false, REQUEST_TO_BIND_MODAL_STORE.CLEAR_REQUEST_TO_BIND),
    }),

    { name: REQUEST_TO_BIND_MODAL_STORE.NAME, anonymousActionType: REQUEST_TO_BIND_MODAL_STORE.ANONYMOUS_ACTION },
  ),
);
