import { ReportType } from '@coverforce-platform/cf-common-api-model';
import { Duration } from '@coverforce-platform/cf-common-types';

import { MenuConfig } from '../../../components/menuActionButton/interface';
import { MENU_ITEM_KEY } from '../../../globalConstants';
import { IReportCenterStore } from './interface';

export const REPORT_PAGE = {
  TITLE: 'Reports Center',
  SUB_TITLE:
    'Generate your reports here. You can generate one-off or schedule recurring application or quote level reports.',
};

export const REPORT_CENTER_STORE_META_DATA = {
  FETCH_REPORT_SCHEDULES_LOADING: 'FETCH_REPORT_SCHEDULES_LOADING',
  SET_REPORT_SCHEDULES: 'SET_REPORT_SCHEDULES',
  UPDATE_REPORT_CENTER_STORE_BY_KEY: 'UPDATE_REPORT_CENTER_STORE_BY_KEY',
};

export const REPORT_CENTER_MESSAGES = {
  ERROR_FETCH_REPORT_SCHEDULES: 'Some error occurred while fetching the reporting schedules',
  ERROR_DELETE_REPORT_SCHEDULE: 'Some error occurred while deleting report schedule',
  SUCCESS_DELETE_REPORT_SCHEDULE: 'Report Schedule deleted successfully!',
};

export const REPORT_CENTER_STORE_KEYS: { [key: string]: keyof IReportCenterStore } = {
  DELETE_SCHEDULE_REPORT_LOADING: 'deleteScheduledReportLoading',
};

export const MENU_CONFIG: MenuConfig[] = [
  {
    label: 'Edit Schedule',
    key: MENU_ITEM_KEY.EDIT,
  },
  {
    label: 'Delete Schedule',
    key: MENU_ITEM_KEY.DELETE,
    extraData: { danger: 'danger' },
  },
];

export const ReportTypeInfo = [
  {
    displayValue: 'Bound Applications',
    toolTipText: 'Report listing all the applications bound for the given time period',
    value: ReportType.COMMISSION_PAYMENT,
    columnsInfo: [
      {
        title: 'Coverforce Application ID',
        description: 'The unique Application ID in the system',
      },
      {
        title: 'Relationship Manager Name',
        description: 'Name of the relationship manager for the agency',
      },
      {
        title: 'Insured Name',
        description: 'Name of the insured',
      },
      {
        title: 'Policy Number',
        description: 'The policy number of the bound quote',
      },
      {
        title: 'LOB',
        description: 'The line of business of the application',
      },
      {
        title: 'Insured Location (State)',
        description: 'Primary state location of the insured',
      },
      {
        title: 'Effective Date (Term)',
        description: 'The policy effective date of the application',
      },
      {
        title: 'Carrier',
        description: 'Name of the carrier',
      },
      {
        title: 'Agency Name',
        description: 'Name of the Agency',
      },
      {
        title: 'Broker Code Label',
        description: 'Broker code used in the application',
      },
      {
        title: '$ Premium (Bound)',
        description: 'Bound premium for the application',
      },
      {
        title: 'Application Last Updated',
        description: 'Date when the application was last updated',
      },
    ],
  },
  {
    displayValue: 'Agency Usage (Aggregate Stats)',
    toolTipText:
      'Report listing agency wise statistics for Agents, Applications Created/Quoted/Bound and Premium Quoted/Bound for the given time period',
    value: ReportType.AGENCY_USAGE,
    columnsInfo: [
      {
        title: 'Relationship Manager Name',
        description: 'Name of the relationship manager for the agency',
      },
      {
        title: 'Agency Name',
        description: 'Name of the Agency',
      },
      {
        title: '# Active Agents',
        description: 'Number of agents in the system',
      },
      {
        title: '# Applications Created',
        description: 'Number of applications created in the time period',
      },
      {
        title: '# Applications Quoted',
        description: 'Number of applications quoted in the time period',
      },
      {
        title: '# Quotes Quoted',
        description: 'Number of quotes generated in the time period',
      },
      {
        title: '# Applications Bound',
        description: 'Number of applications bound in the time period',
      },
      {
        title: 'Total $ Premium (Quoted)',
        description: 'Total premium of applications quoted in the time period',
      },
      {
        title: 'Average $ Premium (Quoted)',
        description: 'Sum of averaged premium for all application in the time period',
      },
      { title: 'Premium Bound', description: 'Bound premium for the application' },
    ],
  },
  {
    displayValue: 'Application Details',
    toolTipText: 'Report listing all applications created/modified with their latest status for the given time period',
    value: ReportType.AGENCY_USAGE_APPLICATION_GRANULAR,
    columnsInfo: [
      {
        title: 'Relationship Manager Name',
        description: 'Name of the relationship manager for the agency',
      },
      {
        title: 'Agency Name',
        description: 'Name of the Agency',
      },
      {
        title: 'Agent Name',
        description: 'Name of the Agent',
      },
      {
        title: 'Application Source',
        description: 'The source of the application. Values are Online Store, Portal, API',
      },
      {
        title: 'Agent Email',
        description: 'Email of the agent',
      },
      {
        title: 'Insured Name',
        description: 'Name of the insured',
      },
      {
        title: 'Insured Location (State)',
        description: 'Primary state location of the insured',
      },
      {
        title: 'Policy Effective Date',
        description: 'The policy effective date of the application',
      },
      {
        title: 'LOB (Product)',
        description: 'The line of business of the application',
      },
      {
        title: 'Application Status (In Progress, Quoted, Bound)',
        description: 'Current status of the application. Values are In Progress, Quoted, Referred, Bound',
      },
      {
        title: 'CoverForce APP ID',
        description: 'The unique Application ID in the system',
      },
      {
        title: 'Application Created Date',
        description: 'Date when the application was created',
      },
      {
        title: 'Application Updated Date',
        description: 'Date when the application was last updated',
      },
      {
        title: 'Mailing Address (5 columns - Line1, Line2, City, State, Zip)',
        description: 'Mailing Address (5 columns - Line1, Line2, City, State, Zip)',
      },
      {
        title: 'Average Premium Quoted',
        description: 'Sum of averaged premium for all application in the time period',
      },
    ],
  },
  {
    displayValue: 'Carrier Usage Summary',
    toolTipText:
      'Report listing aggregated statistics (applications created, quoted, bound) by carrier and policy type for the given time period',
    value: ReportType.CARRIER_SUMMARY,
    columnsInfo: [
      {
        title: 'Relationship Manager Name',
        description: 'Name of the relationship manager for the agency',
      },
      {
        title: 'Carrier Name',
        description: 'Name of the carrier',
      },
      {
        title: 'LOB (Product)',
        description: 'The line of business of the application',
      },
      {
        title: '# Started Applications',
        description: 'Number of applications created in the time period',
      },
      {
        title: '# Quoted Applications',
        description: 'Number of applications quoted in the time period',
      },
      {
        title: '# Bound Applications (in Q+B)',
        description: 'Number of applications bound in the time period',
      },
      {
        title: '$ Bound Applications (in Q+B)',
        description: 'Total premium of applications bound in the time period',
      },
    ],
  },
  {
    displayValue: 'Carrier Usage Summary - By Primary State',
    toolTipText:
      'Report listing aggregated statistics (applications created, quoted, bound) by carrier, primary state and policy type for the given time period',
    value: ReportType.CARRIER_LOCATION_SUMMARY,
    columnsInfo: [
      {
        title: 'Insured Location (State)',
        description: 'Primary state location of the insured',
      },
      {
        title: 'Carrier Name',
        description: 'Name of the carrier',
      },
      {
        title: 'LOB (Product)',
        description: 'The line of business of the application',
      },
      {
        title: '# Started Applications',
        description: 'Number of applications created in the time period',
      },
      {
        title: '# Quoted Applications',
        description: 'Number of applications quoted in the time period',
      },
      {
        title: '# Bound Applications (in Q+B)',
        description: 'Number of applications bound in the time period',
      },
      {
        title: '$ Bound Applications (in Q+B)',
        description: 'Total premium of applications bound in the time period',
      },
    ],
  },
  {
    displayValue: 'Active Agent Details',
    toolTipText: 'Report listing number of applications created/quoted by agents in the given time period',
    value: ReportType.ACTIVE_AGENTS,
    columnsInfo: [
      {
        title: 'Relationship Manager',
        description: 'Name of the relationship manager for the agency',
      },
      {
        title: 'Agency Name',
        description: 'Name of the Agency',
      },
      {
        title: 'First Name',
        description: 'First Name',
      },
      {
        title: 'Last Name',
        description: 'Last Name',
      },
      {
        title: 'Email',
        description: 'Email of the agent',
      },
      {
        title: 'Phone Number',
        description: 'Phone Number of the agent',
      },
      {
        title: 'Role',
        description: `User's role in the system`,
      },
      {
        title: '# Applications Created',
        description: 'Number of applications created in the time period',
      },
      {
        title: '# Applications Quoted',
        description: 'Number of applications quoted in the time period',
      },
      {
        title: 'Agency Network Name',
        description: 'Name of the Agency Network/Wholesaler',
      },
      {
        title: 'Agency Network ID',
        description: 'The unique Agency Network ID in the system',
      },
    ],
  },
  {
    displayValue: 'Quote Details',
    toolTipText: 'Report listing all quotes for applications quoted with their latest status for the given time period',
    value: ReportType.AGENCY_USAGE_QUOTE_STATUS_GRANULAR,
    columnsInfo: [
      {
        title: 'Relationship Manager',
        description: 'Name of the relationship manager for the agency',
      },
      {
        title: 'Agency Name',
        description: 'Name of the Agency',
      },
      {
        title: 'Agent Name',
        description: 'Name of the Agent',
      },
      {
        title: 'Agent Email',
        description: 'Email of the agent',
      },
      {
        title: 'Insured Name',
        description: 'Name of the insured',
      },
      {
        title: 'Insured Location',
        description: 'Primary state location of the insured',
      },
      {
        title: 'Policy Effective Date',
        description: 'The policy effective date of the application',
      },
      {
        title: 'Policy Type',
        description: 'The line of business of the application',
      },
      {
        title: 'Application Status',
        description: 'Current status of the application. Values are In Progress, Quoted, Referred, Bound',
      },
      {
        title: 'Coverforce Application ID',
        description: 'The unique Application ID in the system',
      },
      {
        title: 'Application Source',
        description: 'The source of the application. Values are Online Store, Portal, API',
      },
      { title: 'Carrier', description: 'Name of the carrier' },
      { title: 'Carrier QuoteID', description: `The unique quote ID on the carrier's system` },
      {
        title: 'Quote Type',
        description: 'Type of quote returned by carrier. Values are Bridge, Refer, Bindable, Decline',
      },
      {
        title: 'Quote Created',
        description: 'Date when the quote was created',
      },
      { title: 'Agency Network ID', description: 'The unique Agency Network ID in the system' },
      {
        title: 'Agency Network Name',
        description: 'Name of the Agency Network/Wholesaler',
      },
    ],
  },
];

export const ReportDurationInfo = [
  {
    displayValue: 'Current week',
    toolTipText: 'Data from the last seven days',
    value: Duration.CURRENT_WEEK,
  },
  {
    displayValue: 'Last week',
    toolTipText: 'Data from the previous week, Monday to Sunday',
    value: Duration.LAST_WEEK,
  },
  {
    displayValue: 'Last 2 weeks',
    toolTipText: 'Data from the previous 2 weeks, Monday to Sunday',
    value: Duration.LAST_TWO_WEEKS,
  },
  {
    displayValue: 'Month to date',
    toolTipText: 'Data from the start of the current month until today',
    value: Duration.MTD,
  },
  {
    displayValue: 'Year to date',
    toolTipText: 'Data from the start of the current year until today',
    value: Duration.YTD,
  },
  {
    displayValue: 'Last 24 hours',
    toolTipText: 'Data from the past 24 hours',
    value: Duration.LAST_24_HOURS,
  },
  {
    displayValue: 'All time',
    toolTipText: 'Data from the beginning to the present',
    value: Duration.ALL_TIME,
  },
];
