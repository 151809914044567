import { LOCAL_STORAGE_KEYS, QUERY_PARAMS } from '../globalConstants';
import { getFromLocalStorage } from './getFromLocalStorage';

interface ILogoutActions {
  redirectToHome?: boolean;
}

export const redirectToLogout = async (logoutParams?: ILogoutActions) => {
  const { redirectToHome = false } = logoutParams || {};
  const clientApp = getFromLocalStorage(LOCAL_STORAGE_KEYS.CLIENT_APP) || '';
  const returnToUrl = `${redirectToHome ? '/' : location.pathname}${location.search}`?.trim();

  const logoutUrl = `/logout?${QUERY_PARAMS.CLIENT_APP}=${clientApp}&${QUERY_PARAMS.RETURN_TO}=${returnToUrl}&${QUERY_PARAMS.IS_LOGOUT}=${redirectToHome}`;

  window.location.href = logoutUrl;
};
