import {
  IAgentPasswordChangeInput,
  IDeleteApplicationFileInput,
  IDeleteBrokerCodeInput,
  IDeleteLocationInput,
  IDeleteReportScheduleInput,
  IDownloadApplicationFileInput,
  IGetAcordFormForTransactionInput,
  IGetAgencyNetworkMetadataInput,
  IGetAgentPortalThemeInput,
  IGetApplicationFieldsConfigInput,
  IGetApplicationInput,
  IGetAvailableCarriersForAgentInput,
  IGetCarrierRankingInput,
  IGetLoginAndRegPageConfigInput,
  IGetModifiersForApplicationInput,
  IGetPaymentPlansInput,
  IGetPublicAPIClientInfoByAgencyInput,
  IGetPublicAPIClientInfoByAgencyNetworkInput,
  IGetPublicAPIClientInfoInput,
  IGetQuoteListInput,
  IGetQuoteProposalInput,
  IGetStatementsInput,
  IGetUWQuestionsInput,
} from '@coverforce-platform/cf-common-api-model';

import { REACT_APP_API_BASE_URL, REACT_APP_API_VERSION } from '../../helpers/EnvironmentVariables';

const accountServiceBaseUrl = `${REACT_APP_API_BASE_URL}/${REACT_APP_API_VERSION}`;

export const ACCOUNT_SERVICE_ENDPOINTS = {
  GET_AGENT_BY_USERNAME_OR_AGENCY_ID_OR_AGENCY_NETWORK_ID: '/agents',
  ADD_AGENT: '/agents',
  UPDATE_AGENT: '/agents',
  DELETE_AGENT: (agentId: string) => `/agents/${agentId}`,
  GET_AGENT_BY_AGENT_ID: (agentId: string) => `/agents/${agentId}`,
  GET_ACCOUNTS: () => {
    return `/accounts`;
  },
  ADD_ACCOUNT: '/accounts',
  UPDATE_ACCOUNT: '/accounts',
  DELETE_ACCOUNT: (accountId: string) => {
    return `/accounts/${accountId}`;
  },
  RESET_PASSWORD: (params: IAgentPasswordChangeInput) => `/agents/${params.agentId}/changePassword`,
  GET_ACCOUNT_BY_ACCOUNT_ID: (accountId: string) => `/accounts/${accountId}`,
  ADD_BROKER_CODE: '/brokerCodes',
  UPDATE_BROKER_CODE: '/brokerCodes',
  DELETE_BROKER_CODE: (params: IDeleteBrokerCodeInput) =>
    `${accountServiceBaseUrl}/brokerCodes/${params.agencyOrNetworkId}/${params.carrierId}/${params.brokerConfigurationId}`,
  TOGGLE_BROKER_CODE_STATUS: 'broker-codes/toggle-status',
  SET_DEFAULT_BROKER_CODE: 'brokerCodes/default',
  ACCOUNTS_SEARCH: '/accounts/search',
  GET_AVAILABLE_CARRIERS_BY_AGENCY_ID: (agencyId: string) => `/agencies/${agencyId}/availableCarriers`,
  GET_AVAILABLE_CARRIERS_BY_AGENCY_NETWORK_ID: (agencyNetworkId: string) =>
    `/agencyNetworks/${agencyNetworkId}/availableCarriers`,
  REGISTER_AGENT: '/agents/register',
  UPDATE_AVAILABLE_CARRIERS_BY_AGENCY_NETWORK_ID: `/agencyNetworks/availableCarriers`,
  UPDATE_AVAILABLE_CARRIERS_BY_AGENCY_ID: `/agencies/availableCarriers`,
  GET_SSO_TOKEN: '/sso/jwt',
  GET_AGENCY_NETWORK_DETAILS: (agencyNetworkId: string) => `/agencyNetworks/${agencyNetworkId}`,
  UPDATE_AGENCY_NETWORK: `/agencyNetworks`,
  GET_PUBLIC_API_CLIENT_INFO: (params: IGetPublicAPIClientInfoInput) =>
    `/publicAPIClientInfo/${params.publicAPIClientId}`,
  GET_PUBLIC_API_CLIENTS_BY_AGENCY: (params: IGetPublicAPIClientInfoByAgencyInput) =>
    `/agency/${params.agencyId}/publicAPIClientInfo`,
  GET_PUBLIC_API_CLIENTS_BY_AGENCY_NETWORK: (params: IGetPublicAPIClientInfoByAgencyNetworkInput) =>
    `/agencyNetwork/${params.agencyNetworkId}/publicAPIClientInfo`,
};

export const APPLICATION_SERVICE_ENDPOINTS = {
  CREATE_APPLICATION: () => {
    return `/applications`;
  },
  GET_ALL_APPLICATION_BY_ACOUNT_ID: '/applications',
  CLONE_APPLICATION: '/applications/clone',
  GET_UW_QUESTIONS: (params: IGetUWQuestionsInput) => `/applications/${params.applicationId}/uwquestions`,
  DELETE_APPLICATION: (applicationId: string) => `/applications/${applicationId}`,
  GET_UW_STATEMENTS: (params: IGetStatementsInput) => `/applications/${params.applicationId}/statements`,
  CREATE_APPLICATION_FROM_ACORD: `/applications/create-application-from-acord`,
  GET_APPLICATION: (params: IGetApplicationInput) => `/applications/${params.applicationId}`,
  UPDATE_APPLICATION: '/applications',
  GET_AGENCY_BROKER_CODES: `/broker-codes/agency`,
  GET_AGENCY_NETWORK_BROKER_CODES: `/broker-codes/agency-network`,
  GET_AGENT_DEFAULT_BROKER_CODES: (agentId: string) => `broker-codes/default/${agentId}`,
  GET_POLICY_DOCUMENT: '/applications/policy/documents',
  GET_POLICY_DETAILS: '/applications/policy',
  RE_ASSIGN_APPLICATION: 'applications/reassign-agency',
  CREATE_OR_REGENERATE_QUOTES: 'applications/quotes',
  GET_QUOTES_BY_APPLICATION_ID: (params: IGetQuoteListInput) => `/applications/${params.applicationId}/quotes`,
  GET_QUOTE_PROPOSAL: (params: IGetQuoteProposalInput) =>
    `/applications/${params.applicationId}/quotes/${params.cfQuoteId}/quoteProposal`,
  GET_LIABILITY_LIMITS: (params: IGetApplicationInput) => `/applications/${params.applicationId}/liabilityLimits`,
  REFER_QUOTE: '/applications/quotes/refer',
  MARK_AS_BOUND_QUOTE: '/applications/quotes/markBound',
  REQUEST_TO_BIND: `/application/request-bind`,
  CANCEL_REQUEST_TO_BIND: `/application/cancel-bind-request`,

  // Payments
  GET_PAYMENT_PLANS: (params: IGetPaymentPlansInput) =>
    `/applications/${params.applicationId}/quotes/${params.cfQuoteId}/payments`,
  SELECT_PAYMENT_PLAN: `/applications/quotes/paymentplans`,

  BIND_APPLICATION: `/applications/quotes/bind`,
  ADD_LOCATION: '/applications/locations',
  UPDATE_LOCATION: '/applications/locations',
  DELETE_LOCATION: (params: IDeleteLocationInput) =>
    `/applications/${params.applicationId}/locations/${params.locationId}`,
  MARK_AS_BOUND: '/applications/markBound',

  // Dynamic Questions
  GET_APPLICATION_FIELDS_CONFIG: (params: IGetApplicationFieldsConfigInput) =>
    `applications/${params.applicationId}/fields-config`,

  // ACORD APIs
  REQUEST_ACORD: '/application/request-acord',
  DOWNLOAD_ACORD: (params: IGetAcordFormForTransactionInput) =>
    `/application/${params.applicationId}/download-acord/${params.acordDownloadTransactionId}`,

  // File Service
  UPLOAD_FILE: `/applications/files`,
  SEARCH_FILE: '/applications/files/search',
  DOWNLOAD_FILE: (params: IDownloadApplicationFileInput) =>
    `/applications/${params.applicationId}/files/${params.fileId}`,
  DELETE_FILE: (params: IDeleteApplicationFileInput) => `applications/${params.applicationId}/files/${params.fileId}`,

  //Modifiers
  GET_MODIFIERS: (params: IGetModifiersForApplicationInput) => `/applications/${params.applicationId}/modifiers`,
};

export const THEME_SERVICE = {
  ADD_THEME: '/themes/agent-portal',
  UPDATE_THEME: '/themes/agent-portal',
  GET_THEME: (params: IGetAgentPortalThemeInput) => `/themes/agent-portal/${params.themeName}`,
  GET_LOGIN_REGISTRATION_CONFIG: (params: IGetLoginAndRegPageConfigInput) =>
    `/agencyNetworks/${params.clientAppName}/login-page-config`,
};

export const HELPER_SERVICE_ENDPOINTS = {
  CHECK_APPETITE: '/helperservice/check-appetite',
  GET_ALL_POLICY_TYPES: '/helperservice/policy-types',
  GET_NAICS_CODES: '/helperservice/get-all-naics-codes',
  GET_SIC_CODES: '/helperservice/get-all-sic-codes',
  GET_LEGAL_ENTITIES: '/helperservice/get-all-legal-entities',
  GET_NCCI_CODE_BY_STATE: '/helperservice/get-ncci-class-codes-by-state',
  GET_JOB_CODES_BY_STATE: '/helperservice/get-job-codes-by-state',
  GET_NCCI_CODE_BY_CARRIERS: '/helperservice/get-ncci-internal-code-by-carriers',
  GET_INDUSTRY_CLASS_CODES: '/helperservice/industry-class-codes',
  GET_INDUSTRY_CLASS_CODE_DESCRIPTION: '/helperservice/industry-class-code-details',
  GET_ISU_CLPA_DETAILS: '/helperservice/carriers/isu/clpa',
  GET_ALL_CF_OWNERS_OFFICERS_TITLE: '/helperservice/cf-owner-officer-titles',
};

export const CARRIER_SERVICE_ENDPOINTS = {
  GET_AVAILABLE_CARRIERS: (params: IGetAvailableCarriersForAgentInput) => {
    return `/agents/${params.agentId}/availableCarriers`;
  },
  GET_CARRIER_RANKING_BY_POLICY_TYPE: (params: IGetCarrierRankingInput) => {
    return `/carrierRankings/${params.agentId}/${params.policyTypeId}`;
  },
  GET_ALL_CARRIERS: '/helperservice/carriers',
  UPDATE_CARRIER_RANKING: '/carrierRankings',
};

export const S3_BUCKET_ENDPOINTS = {
  AGENCY_LIST: (agencyNetworkId: string) => {
    return `/agencynetworks/${agencyNetworkId}/agencies/${agencyNetworkId}_agencies.json`;
  },
  CARRIER_STATUS: `/agent-portal/dashboard/carrier-status.json`,
  PLATFORM_ANNOUNCEMENTS: `/agent-portal/dashboard/announcements.json`,
};

export const AGENCY_SERVICE_ENDPOINTS = {
  AGENCY_BY_ID: (agencyId: string) => `/agencies/${agencyId}`,
  AGENCIES: '/agencies',
};

export const AGENCY_NETWORK_SERVICE_ENDPOINTS = {
  GET_AGENCY_NETWORK_APPLICATIONS: '/agency-networks/applications',
  GET_AGENCY_NETWORK_USERS: '/agency-network-users',
  GET_AGENCY_NETWORK_METADATA: (params: IGetAgencyNetworkMetadataInput) =>
    `/agencyNetworks/${params.agencyNetworkId}/metadata`,
  UPDATE_AGENCY_NETWORK_METADATA: `/agencyNetwork/metadata`,
};

export const APPLICATION_LIFECYCLE_SERVICE_ENDPOINTS = {
  GET_ALL_REPORTS_SCHEDULES: '/reports/get-report-schedule',
  GENERATE_REPORT: '/reports/request-report',
  CREATE_REPORT_SCHEDULE: '/reports/report-schedule',
  UPDATE_REPORT_SCHEDULE: '/reports/report-schedule',
  DELETE_REPORT_SCHEDULE: (params: IDeleteReportScheduleInput) => `/reports/report-schedule/${params.scheduleId}`,
};
