import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { cancelRequestToBindQuote as cancelRequestToBindQuoteRequest } from '../../externalServices/V2/quotes';
import { useProfileV2Store } from '../../pages/v2/profile/store';
import { useQuotesStore } from '../quotes/store';
import { CANCEL_REQUEST_TO_BIND_MODAL_STORE } from './constants';
import { ICancelRequestToBindStore } from './interface';

export const useCancelRequestToBindQuoteModalStore = create<ICancelRequestToBindStore>()(
  devtools(
    (set) => ({
      isCancelRequestToBindLoading: false,

      cancelRequestToBindQuote: async (isCancelRequestToBindLoading = true) => {
        set({ isCancelRequestToBindLoading }, false, CANCEL_REQUEST_TO_BIND_MODAL_STORE.CANCEL_REQUEST_TO_BIND_LOADING);

        const { selectedQuote } = useQuotesStore.getState();
        const { profileData } = useProfileV2Store.getState();

        const cancelRequestToBindQuoteResponse = await cancelRequestToBindQuoteRequest({
          applicationId: selectedQuote?.applicationId || '',
          cfQuoteId: selectedQuote?.cfQuoteId || '',
          agentId: profileData?.agentId || '',
        });
        return cancelRequestToBindQuoteResponse;
      },

      updateCancelRequestToBindModalByKey: async (storeKey: keyof ICancelRequestToBindStore, value: any) =>
        set(
          () => ({ [storeKey]: value }),
          false,
          CANCEL_REQUEST_TO_BIND_MODAL_STORE.UPDATE_CANCEL_REQUEST_TO_BIND_MODAL_STORE_BY_KEY,
        ),

      clearCancelRequestToBind: () =>
        set(
          { isCancelRequestToBindLoading: false },
          false,
          CANCEL_REQUEST_TO_BIND_MODAL_STORE.CLEAR_CANCEL_REQUEST_TO_BIND_MODAL,
        ),
    }),

    {
      name: CANCEL_REQUEST_TO_BIND_MODAL_STORE.NAME,
      anonymousActionType: CANCEL_REQUEST_TO_BIND_MODAL_STORE.ANONYMOUS_ACTION,
    },
  ),
);
