import { IAddLocationInput, ILocationInfo, IUpdateLocationInput } from '@coverforce-platform/cf-common-api-model';
import { PolicyType } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  APPLICATION_STORE_KEYS,
  APPLICATION_TABS,
  UPDATE_APPLICATION_STEPS,
} from '../../constants/applicationV2Constants';
import { deleteLocation } from '../../externalServices/V2/location';
import { FEATURE_FLAG } from '../../globalConstants';
import { useApplicationStore } from '../../pages/v2/application/store';
import { isOwnerOfficersEnabled } from '../../pages/v2/application/utils';
import { errorV2 } from '../../ui-core/Notification';
import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';
import { isGrowthbookFeatureEnabledByKey } from '../../utils/growthbook';
import { cloneDeep, set as _set } from '../../utils/lodash';
import { scrollAndFocusFormItemError } from '../../utils/scrollAndFocusFormItemError';
import { useBuildingInformationStore } from '../buildingInformation/store';
import { useBuildingRiskInformationStore } from '../buildingRiskInformation/store';
import { useEmployeeInformationStore } from '../employeeInformation/store';
import { handleAccordionError } from '../employeeInformation/utils';
import { OWNER_OFFICER_DETAILS_STORE_KEYS } from '../ownerOfficerDetails/constants';
import { useOwnerOfficerDetailsStore } from '../ownerOfficerDetails/store';
import { useLocationInputStore } from '../question/locationInputV2/store';
import { validateAddress } from '../question/locationInputV2/utils';
import { LOCATION_DETAILS_ERROR_MESSAGE, LOCATION_DETAILS_STORE_META_DATA } from './constants';
import { ILocationDetailsStore } from './interface';
import {
  addOrUpdateLocationUtil,
  getBaseLocationApiPayload,
  getPolicyTypeSpecificLocationDetailsPayload,
  getSpecificAddOrUpdateLocationDetailsPayload,
} from './utils';

export const useLocationDetailsStore = create<ILocationDetailsStore>()(
  devtools(
    (set, get) => ({
      locations: [],
      locationDetailsForm: undefined,
      isLocationDetailLoading: false,

      setLocationDetailsForm: (locationDetailsForm: IFormInstance) => {
        set({ locationDetailsForm }, false, LOCATION_DETAILS_STORE_META_DATA.SET_LOCATION_FORM);
      },

      setIsLocationDetailLoading: (isLocationDetailLoading: boolean) => {
        set({ isLocationDetailLoading });
      },

      addLocationDetails: async () => {
        const { validateLocationDetails, getLocationDetailsApiPayload } = get();
        const { applicationData, currentSubStepIndex, updateApplicationByKey, fetchApplication } =
          useApplicationStore.getState();
        let locations = [...get().locations] as any;
        const isLocationTabValid = await validateLocationDetails();
        const locationDetailApiPayload = getLocationDetailsApiPayload() as IAddLocationInput | IUpdateLocationInput;
        try {
          if (isLocationTabValid) {
            locations.push({
              isPrimary: false,
            });
            // Condition tells if the current location index is less than the number of total locations
            // then allow the add or update location
            if (currentSubStepIndex < locations?.length) {
              await addOrUpdateLocationUtil({
                locationPayload: locationDetailApiPayload,
                applicationData: applicationData!,
              });
              const applicationResponse = await fetchApplication(applicationData?.applicationId || '');
              if (applicationResponse?.locationDetails && applicationResponse?.locationDetails?.length > 0) {
                locations = [...applicationResponse.locationDetails, { isPrimary: false }];
              }
              updateApplicationByKey(APPLICATION_STORE_KEYS.CURRENT_SUBSTEP_INDEX, locations?.length - 1);
            }
          }
          set({ locations }, false, LOCATION_DETAILS_STORE_META_DATA.ADD_OR_UPDATE_LOCATION);
        } catch (error: any) {
          errorV2(error?.[0]?.errorMessage || LOCATION_DETAILS_ERROR_MESSAGE.FAILED_ADD_OR_UPDATE_LOCATION);
        }
      },

      updateLocationDetails: (locationIndex: number, updatedLocation: ILocationInfo) => {
        const { locations: storeLocations } = get();
        const locations = [...storeLocations];
        set(
          {
            locations: _set(locations, [locationIndex], updatedLocation),
          },
          false,
          LOCATION_DETAILS_STORE_META_DATA.UPDATE_STORE_LOCATION,
        );
      },

      deleteLocationDetails: async (locationIndex: number) => {
        const locations = [...get().locations];
        const { applicationData, updateApplicationByKey, fetchApplication, updateApplication, currentSubStepIndex } =
          useApplicationStore.getState();
        const { updateOwnerOfficerDetailsStoreByKey } = useOwnerOfficerDetailsStore.getState();

        const applicationId = applicationData?.applicationId || '';
        const locationId = locations[locationIndex]?.locationId || '';

        updateApplicationByKey(APPLICATION_STORE_KEYS.IS_APPLICATION_LOADING, true);

        // If location present in application that means we will be having location id
        try {
          if (locationId) {
            await deleteLocation({ applicationId, locationId });
            if (isOwnerOfficersEnabled()) {
              const filterOwnerOfficerList =
                applicationData?.companyStructure?.ownershipDetails?.owners?.filter(
                  (ownerOfficerDetails) => ownerOfficerDetails?.cfLocationId !== locationId,
                ) || [];
              updateOwnerOfficerDetailsStoreByKey(
                OWNER_OFFICER_DETAILS_STORE_KEYS.OWNER_OFFICER_DETAILS_LIST,
                filterOwnerOfficerList,
              );
              await updateApplication({
                updateApplicationStep: UPDATE_APPLICATION_STEPS.OWNEROFFICER,
              });
            }
            locations?.splice(locationIndex, 1);
            set({ locations });
            await fetchApplication(applicationData?.applicationId || '');
            if (locationIndex <= currentSubStepIndex) {
              updateApplicationByKey(APPLICATION_STORE_KEYS.CURRENT_SUBSTEP_INDEX, currentSubStepIndex - 1);
            }
          }
          // to handle the last location
          else if (!locationId && locationIndex === locations?.length - 1) {
            locations?.splice(locationIndex, 1);
            set({ locations });
            await fetchApplication(applicationData?.applicationId || '');
            if (locationIndex <= currentSubStepIndex) {
              updateApplicationByKey(APPLICATION_STORE_KEYS.CURRENT_SUBSTEP_INDEX, currentSubStepIndex - 1);
            }
          }
        } catch (error: any) {
          errorV2(error?.[0]?.errorMessage || LOCATION_DETAILS_ERROR_MESSAGE.DELETE_LOCATION);
        }
        updateApplicationByKey(APPLICATION_STORE_KEYS.IS_APPLICATION_LOADING, false);
      },

      autoFillLocationDetails: async () => {
        const { locationDetailsForm, setIsLocationDetailLoading } = get();
        setIsLocationDetailLoading(true);
        const {
          applicationData,
          currentSubStepIndex: selectedLocationIndex,
          policyType,
          currentStep,
        } = useApplicationStore.getState();
        const { autoFillBuidlingInformation } = useBuildingInformationStore.getState();
        const { autoFillEmployeeInfo } = useEmployeeInformationStore.getState();
        const { autoFillBuildingRiskInformation } = useBuildingRiskInformationStore.getState();

        const locations = applicationData?.locationDetails;

        if (locations) {
          locationDetailsForm?.setFields([
            { name: ['location', selectedLocationIndex], value: locations[selectedLocationIndex]?.address },
          ]);
        }

        set({ locations: applicationData?.locationDetails });
        if (currentStep === APPLICATION_TABS.LOCATIONS) {
          switch (policyType) {
            case PolicyType.WC:
              autoFillEmployeeInfo();
              break;
            case PolicyType.BOP:
              autoFillBuidlingInformation();
              break;
            case PolicyType.BR:
              autoFillBuildingRiskInformation();
              break;
            default:
              break;
          }
        }
        setIsLocationDetailLoading(false);
      },

      validateLocationDetails: async () => {
        const { locationDetailsForm } = get();
        const { currentSubStepIndex: selectedLocationIndex, policyType } = useApplicationStore.getState();
        const { setShowLocationError, addressData } = useLocationInputStore.getState();
        const locationData = addressData[`location_${selectedLocationIndex}`]?.address;
        const isRedesignLocationTab = isGrowthbookFeatureEnabledByKey(FEATURE_FLAG.REDESIGN_LOCATION_TAB);

        let isLocationTabDataValid = true;
        try {
          if (!validateAddress(locationData)) {
            setShowLocationError(true, `location_${selectedLocationIndex}`);
            isLocationTabDataValid = false;
          }
          switch (policyType) {
            case PolicyType.WC:
            case PolicyType.BOP:
            case PolicyType.BR:
              await locationDetailsForm?.validateFields();
              break;

            default:
              break;
          }
        } catch (err) {
          isLocationTabDataValid = false;
          policyType === PolicyType.WC && isRedesignLocationTab && handleAccordionError(err);
          const errorFields = await locationDetailsForm?.getFieldsError();
          const formErr = errorFields?.find((error: any) => error.errors?.length > 0);
          if (formErr) {
            scrollAndFocusFormItemError(formErr);
          }
        }
        return isLocationTabDataValid;
      },

      getLocationDetailsApiPayload: () => {
        const { locations } = get();
        const { addressData } = useLocationInputStore.getState();
        const { applicationData } = useApplicationStore.getState();
        const { policyType, currentSubStepIndex: selectedLocationIndex } = useApplicationStore.getState();
        const applicationsSelectedLocationDetail = locations?.[selectedLocationIndex];
        const selectedLocationDetail = addressData[`location_${selectedLocationIndex}`]?.address;
        // Common Location Payload
        const commonRequestPayload = getBaseLocationApiPayload({
          applicationData,
          selectedLocationDetail,
          selectedLocationIndex,
        });

        const requestPayload: IUpdateLocationInput | IAddLocationInput = getPolicyTypeSpecificLocationDetailsPayload(
          commonRequestPayload,
          policyType!,
        );

        return getSpecificAddOrUpdateLocationDetailsPayload(applicationsSelectedLocationDetail, requestPayload);
      },

      getLocationApplicationApiPayload: () => {
        const { applicationData } = useApplicationStore.getState();
        const { getLocationDetailsApiPayload } = get();
        const locationDetailApiPayload = getLocationDetailsApiPayload();
        const applicationDataPayload = cloneDeep(applicationData);
        _set(
          applicationDataPayload!,
          ['basicBusinessDetails', 'mailingAddress'],
          locationDetailApiPayload?.locationDetails?.address,
        );
        return applicationDataPayload;
      },

      clearLocationDetails: () => {
        const { locationDetailsForm } = get();
        const { clearEmployeeInformation } = useEmployeeInformationStore.getState();
        locationDetailsForm?.resetFields();
        set(
          { locationDetailsForm: undefined, isLocationDetailLoading: false },
          false,
          LOCATION_DETAILS_STORE_META_DATA.CLEAR_LOCATION_DETAILS,
        );
        clearEmployeeInformation();
      },
    }),
    {
      anonymousActionType: LOCATION_DETAILS_STORE_META_DATA.ANONYMOUS_ACTION_NAME,
      name: LOCATION_DETAILS_STORE_META_DATA.STORE_NAME,
    },
  ),
);
