import { IReportSchedule } from '@coverforce-platform/cf-common-api-model/lib/models/applicationLifecycleService/reports/common/reportSchedule.model';
import { AgentPortalUserType } from '@coverforce-platform/cf-common-types';
import dayjs from 'dayjs';

import { DATE_FORMAT } from '../../globalConstants';
import { useProfileV2Store } from '../../pages/v2/profile/store';
import { DROPDOWN_MODE } from '../../ui-core/V2/cfSelect/constants';
import { useConfigurationStore } from '../configuration/store';
import { SCHEDULE_REPORT_QUESTION_KEYS, SCHEDULE_REPORT_STORE_KEYS } from './constants';
import { IScheduleReportQuestion } from './interface';
import { useScheduleReportStore } from './store';

const resetDateRangeFieldsOnEditForm = () => {
  const { scheduleReportForm, isEditReportSchedule, updateScheduleReportStoreByKey } =
    useScheduleReportStore.getState();
  if (isEditReportSchedule) {
    // if the start date is less than today, then reset the start date
    if (
      scheduleReportForm?.getFieldValue([SCHEDULE_REPORT_QUESTION_KEYS.TIME_FRAME_DATE_RANGE])?.[0] &&
      dayjs(scheduleReportForm?.getFieldValue([SCHEDULE_REPORT_QUESTION_KEYS.TIME_FRAME_DATE_RANGE])?.[0])?.format(
        DATE_FORMAT.YYYYMMDD,
      ) < dayjs()?.add(1, 'day').format(DATE_FORMAT.YYYYMMDD)
    ) {
      scheduleReportForm?.setFieldsValue({
        [SCHEDULE_REPORT_QUESTION_KEYS.TIME_FRAME_DATE_RANGE]: undefined,
      });

      scheduleReportForm?.setFields([
        {
          name: SCHEDULE_REPORT_QUESTION_KEYS.TIME_FRAME_DATE_RANGE,
          errors: ['Please enter Report time frame Start Date.'],
        },
      ]);

      updateScheduleReportStoreByKey(SCHEDULE_REPORT_STORE_KEYS.DISABLE_SCHEDULE_REPORT_BUTTON, false);
    }
  }
};

const handleMultiModeChange = () => {
  resetDateRangeFieldsOnEditForm();
};

export const handleFieldOnChange = async (entry: any, data: any) => {
  const { isEditReportSchedule, updateScheduleReportStoreByKey } = useScheduleReportStore.getState();
  switch (data?.dataIndex) {
    // To Skip the on change event for the Schedule start and end date
    case SCHEDULE_REPORT_QUESTION_KEYS.TIME_FRAME_DATE_RANGE:
      if (isEditReportSchedule) {
        updateScheduleReportStoreByKey(SCHEDULE_REPORT_STORE_KEYS.DISABLE_SCHEDULE_REPORT_BUTTON, false);
      }
      break;

      break;

    default:
      resetDateRangeFieldsOnEditForm();
      break;
  }
};

export const handleScheduleReportFieldChange = (question: IScheduleReportQuestion) => {
  return question.mode === DROPDOWN_MODE.TAGS
    ? {
        onMultiModeChange: handleMultiModeChange,
      }
    : {
        onChange: handleFieldOnChange,
      };
};

export const getScheduleReportTitle = () => {
  const { isEditReportSchedule } = useScheduleReportStore.getState();
  return isEditReportSchedule ? 'Edit Schedule Report' : 'Schedule Report';
};

export const autoFillScheduleReportFormUtil = () => {
  const { reportSchedule, scheduleReportForm } = useScheduleReportStore.getState();
  scheduleReportForm?.setFieldsValue({
    [SCHEDULE_REPORT_QUESTION_KEYS.REPORT_TYPE]: reportSchedule?.reportType,
    [SCHEDULE_REPORT_QUESTION_KEYS.TO_EMAIL_LIST]: reportSchedule?.recipientEmailIds?.toEmailList,
    [SCHEDULE_REPORT_QUESTION_KEYS.SCHEDULE_NAME]: reportSchedule?.schedule?.scheduleName,
    [SCHEDULE_REPORT_QUESTION_KEYS.TIME_FRAME_DATE_RANGE]: reportSchedule?.schedule?.scheduleStartDate
      ? [
          reportSchedule?.schedule?.scheduleStartDate
            ? dayjs(
                getLocalFromUtc({
                  utcDateString: reportSchedule?.schedule?.scheduleStartDate,
                  utcTimeString: reportSchedule?.schedule?.preferredTimeOfDelivery,
                })?.localDateString,
              )
            : undefined,
          reportSchedule?.schedule?.scheduleEndDate
            ? dayjs(
                getLocalFromUtc({
                  utcDateString: reportSchedule?.schedule?.scheduleEndDate,
                  utcTimeString: reportSchedule?.schedule?.preferredTimeOfDelivery,
                })?.localDateString,
              )
            : undefined,
        ]
      : undefined,
    [SCHEDULE_REPORT_QUESTION_KEYS.FREQUENCY_OF_DELIVERY]: reportSchedule?.schedule?.frequencyOfDelivery,
    [SCHEDULE_REPORT_QUESTION_KEYS.PREFERRED_TIME_OF_DELIVERY]: reportSchedule?.schedule?.preferredTimeOfDelivery
      ? dayjs(
          getLocalFromUtc({ utcTimeString: reportSchedule?.schedule?.preferredTimeOfDelivery })?.localTimeString,
          'hh:mm:ss',
        )
      : undefined,

    [SCHEDULE_REPORT_QUESTION_KEYS.REPORT_DURATION_FOR_DELIVERY]: reportSchedule?.schedule?.reportDurationForDelivery,
  });
};

export const getScheduleReportApiPayloadUtil = () => {
  const { scheduleReportForm, isEditReportSchedule, reportSchedule } = useScheduleReportStore.getState();
  const scheduleReportFormAnswers = scheduleReportForm?.getFieldsValue();
  const { userType } = useConfigurationStore.getState();
  const { profileData } = useProfileV2Store.getState();

  const payload: IReportSchedule = {
    agencyNetworkId: profileData?.agencyNetworkId ?? '',
    agencyId: userType === AgentPortalUserType.AGENCY ? profileData?.agencyId ?? '' : undefined,
    reportType: scheduleReportFormAnswers?.[SCHEDULE_REPORT_QUESTION_KEYS.REPORT_TYPE],
    recipientEmailIds: { toEmailList: scheduleReportFormAnswers?.[SCHEDULE_REPORT_QUESTION_KEYS.TO_EMAIL_LIST] },
    schedule: {
      scheduleName: scheduleReportFormAnswers?.[SCHEDULE_REPORT_QUESTION_KEYS.SCHEDULE_NAME],

      scheduleStartDate:
        getUtcFromLocal({
          localDateString: scheduleReportFormAnswers?.[
            SCHEDULE_REPORT_QUESTION_KEYS.TIME_FRAME_DATE_RANGE
          ]?.[0]?.format(DATE_FORMAT.YYYYMMDD),
          localTimeString:
            scheduleReportFormAnswers?.[SCHEDULE_REPORT_QUESTION_KEYS.PREFERRED_TIME_OF_DELIVERY]?.format('HH:mm:ss'),
        })?.utcDateString ?? '',

      scheduleEndDate: scheduleReportFormAnswers?.[SCHEDULE_REPORT_QUESTION_KEYS.TIME_FRAME_DATE_RANGE]?.[1]?.format(
        DATE_FORMAT.YYYYMMDD,
      )
        ? getUtcFromLocal({
            localDateString: scheduleReportFormAnswers?.[
              SCHEDULE_REPORT_QUESTION_KEYS.TIME_FRAME_DATE_RANGE
            ]?.[1]?.format(DATE_FORMAT.YYYYMMDD),
            localTimeString:
              scheduleReportFormAnswers?.[SCHEDULE_REPORT_QUESTION_KEYS.PREFERRED_TIME_OF_DELIVERY]?.format('HH:mm:ss'),
          })?.utcDateString ?? ''
        : undefined,

      frequencyOfDelivery: scheduleReportFormAnswers?.[SCHEDULE_REPORT_QUESTION_KEYS.FREQUENCY_OF_DELIVERY],

      preferredTimeOfDelivery: getUtcFromLocal({
        localTimeString:
          scheduleReportFormAnswers?.[SCHEDULE_REPORT_QUESTION_KEYS.PREFERRED_TIME_OF_DELIVERY]?.format('HH:mm:ss'),
      })?.utcTimeString,
      reportDurationForDelivery:
        scheduleReportFormAnswers?.[SCHEDULE_REPORT_QUESTION_KEYS.REPORT_DURATION_FOR_DELIVERY],
      scheduledId: isEditReportSchedule ? reportSchedule?.schedule?.scheduledId : undefined,
    },
  };

  return payload;
};

export const getUtcFromLocal = ({
  localDateString,
  localTimeString,
}: {
  localDateString?: string;
  localTimeString?: string;
}) => {
  if (!localTimeString) {
    return undefined;
  }

  const currentDate = dayjs()?.format('YYYY-MM-DD');
  const localDateTimeString = `${localDateString ?? currentDate} ${localTimeString}`;
  const localDateTime = dayjs(localDateTimeString, 'YYYY-MM-DD HH:mm:ss');
  const utcDateTime = localDateTime?.utc();
  const utcTimeString = utcDateTime?.format('HH:mm:ss');
  const utcDateString = utcDateTime?.format('YYYY-MM-DD');

  return { utcTimeString, utcDateString };
};

export const getLocalFromUtc = ({
  utcDateString,
  utcTimeString,
}: {
  utcDateString?: string;
  utcTimeString?: string;
}) => {
  if (!utcTimeString) {
    return undefined;
  }

  const currentDate = dayjs()?.format('YYYY-MM-DD');
  const utcDateTimeString = `${utcDateString ?? currentDate} ${utcTimeString}`;
  const utcDateTime = dayjs.utc(utcDateTimeString, 'YYYY-MM-DD HH:mm:ss');
  const localDateTime = utcDateTime?.local();
  const localTimeString = localDateTime?.format('HH:mm:ss');
  const localDateString = localDateTime?.format('YYYY-MM-DD');

  return { localTimeString, localDateString };
};
