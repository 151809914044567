import { JWT_TOKEN } from '../../globalConstants';
import { getFromLocalStorage } from '../../utils/getFromLocalStorage';
import { uniqueId } from '../../utils/uniqueId';
import { IRequestCommonHeaders } from './interface';

export const REQUEST_COMMON_HEADERS = (): IRequestCommonHeaders => {
  return {
    authorization: `Bearer ${getFromLocalStorage(JWT_TOKEN.ACCESS_TOKEN)}`,
    'content-type': 'application/json',
    'x-request-id': `cfr-${uniqueId()}`,
  };
};
