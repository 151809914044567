/* eslint-disable react/display-name */
import { AgentPortalUserType } from '@coverforce-platform/cf-common-types';

import { NAVBAR_ID } from '../../globalConstants';
import { PageLoaderStyled } from '../../globalStyles';
import { useProfileV2Store } from '../../pages/v2/profile/store';
import CFLoader from '../../ui-core/V2/cfLoader/cfLoader';
import { redirectToLogout } from '../../utils/redirectToLogout';
import { useConfigurationStore } from '../configuration/store';
import Footer from '../footer/footer';
import NavBar from '../navBarV2/navBar';
import Popups from '../popups/popups';
import UnAuthorisedElement from '../unAuthorisedElement/unAuthorisedElement';
import { ElementWrapperStyled, LayoutWrapperStyled, NavbarWrapperStyled } from './styles';

const withLayout =
  (Element: any, navbar = true, footer = true) =>
  (props: any) => {
    const {
      isAuthenticatedRoute = true,
      authorisedUserTypes,
      restrictedRoute,
    }: {
      isAuthenticatedRoute: boolean;
      authorisedUserTypes?: AgentPortalUserType[];
      restrictedRoute?: boolean;
    } = props;

    const { userType } = useConfigurationStore();
    const { authLoading, profileData } = useProfileV2Store();

    const showUnauthorisedComponent = () => {
      // 'restrictedRoute' is supposed to be set at 'appRoutes' level via metadata API response information
      return (authorisedUserTypes && !authorisedUserTypes?.includes(userType)) || restrictedRoute;
    };

    return (
      <LayoutWrapperStyled>
        {/* This will be true till the GET /agents call is in progress and hence loader will be displayed */}
        {authLoading && (
          <PageLoaderStyled>
            <CFLoader />
          </PageLoaderStyled>
        )}
        {/* This means GET /agents call is now complete, be it success/failure */}
        {!authLoading && (
          <>
            {/* If GET /agents call was failed, profileData will be null and hence user will be logged-out (for authenticated route) */}
            {isAuthenticatedRoute && !profileData && redirectToLogout()}
            {/* If GET /agents call was success, profileData will have the current agent data and hence rendering continues */}
            {/* Or if it's non-authenticated route, profileLoading was explicitly set to false from Configuration component */}
            {(!isAuthenticatedRoute || profileData) && (
              <>
                {navbar && (
                  <NavbarWrapperStyled id={NAVBAR_ID}>
                    <NavBar />
                  </NavbarWrapperStyled>
                )}
                <ElementWrapperStyled>
                  {/** The below logic tells whether a route is authorised for a particular user or not
                   *  If a route doesn't have authorisedUserTypes entity given in appRoutes, it means that is userType agnostic and should be available for any userType.
                   *  If a route does have authorisedUserTypes entity given in appRoutes, it means it is only for that specific userType and any other userType should be unauthorised to access this page.
                   */}
                  {showUnauthorisedComponent() ? <UnAuthorisedElement /> : <Element {...props} />}
                </ElementWrapperStyled>
                {footer && <Footer />}
                <Popups />
              </>
            )}
          </>
        )}
      </LayoutWrapperStyled>
    );
  };

export default withLayout;
