// INFO: commented account related methods and attributes because it might be useful in future.
import { AgentPortalUserType } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { useConfigurationStore } from '../../../components/configuration/store';
import {
  getPublicApiClientsByAgency,
  getPublicApiClientsByAgencyNetwork,
} from '../../../externalServices/V2/accountsV2';
// import { getAccountsBySearch } from '../../../externalServices/V2/accountsV2';
import { getAgencyNetworkApplications } from '../../../externalServices/V2/agencyNetwork';
import { DEFAULT_PAGINATION } from '../../../globalConstants';
import { isEmpty, omitBy } from '../../../utils/lodash';
import { useProfileV2Store } from '../profile/store';
import { APPLICATIONS_STORE_META_DATA } from './constants';
import { IApplicationsStore } from './interface';
import { modifyApplicationsTableFilter } from './utils';

export const useApplicationsStore = create<IApplicationsStore>()(
  devtools(
    (set, get) => ({
      applicationLoading: false,
      applications: undefined,
      pagination: undefined,
      applicationError: null,
      publicApiClients: undefined,
      publicApiClientsLoading: false,
      // accountsLoading: false,
      // accounts: [],
      filters: undefined,

      setFilters: (filters?: any) => {
        set({ filters }, false, APPLICATIONS_STORE_META_DATA.APPLICATIONS_SET_FILTERS);
      },

      // fetchAccounts: async () => {
      //   set({ accountsLoading: true }, false, 'fetchAccountsLoadingStart');
      //   try {
      //     const response = await getAccountsBySearch({
      //       agencyNetworkId: [useProfileV2Store.getState().profileData?.agencyNetworkId || ''],
      //       size: DEFAULT_PAGINATION.SIZE.toString(),
      //     });
      //     set({ accounts: response.accounts }, false, 'fetchAccounts');
      //   } catch (err: any) {
      //     set({ accounts: [] }, false, 'fetchAccountsError');
      //   } finally {
      //     set({ accountsLoading: false }, false, 'fetchAccountsLoadingStop');
      //   }
      // },

      fetchPublicApiClientsByAgency: async () => {
        try {
          set({ publicApiClientsLoading: true }, false, APPLICATIONS_STORE_META_DATA.PUBLIC_API_CLIENTS_LOADING_START);
          const response = await getPublicApiClientsByAgency({
            agencyId: useProfileV2Store.getState()?.profileData?.agencyId || '',
          });
          set(
            { publicApiClients: response?.publicAPIClientInfos ?? [] },
            false,
            APPLICATIONS_STORE_META_DATA.SET_PUBLIC_API_CLIENTS_BY_AGENCY,
          );
        } catch (error: any) {
          set({ publicApiClients: [] }, false, APPLICATIONS_STORE_META_DATA.SET_PUBLIC_API_CLIENTS_BY_AGENCY);
        } finally {
          set({ publicApiClientsLoading: false }, false, APPLICATIONS_STORE_META_DATA.PUBLIC_API_CLIENTS_LOADING_STOP);
        }
      },

      fetchPublicApiClientsByAgencyNetwork: async () => {
        try {
          set({ publicApiClientsLoading: true }, false, APPLICATIONS_STORE_META_DATA.PUBLIC_API_CLIENTS_LOADING_START);
          const response = await getPublicApiClientsByAgencyNetwork({
            agencyNetworkId: useProfileV2Store.getState()?.profileData?.agencyNetworkId || '',
          });
          set(
            { publicApiClients: response?.publicAPIClientInfos ?? [] },
            false,
            APPLICATIONS_STORE_META_DATA.SET_PUBLIC_API_CLIENTS_BY_AGENCY_NETWORK,
          );
        } catch (error: any) {
          set({ publicApiClients: [] }, false, APPLICATIONS_STORE_META_DATA.SET_PUBLIC_API_CLIENTS_BY_AGENCY_NETWORK);
        } finally {
          set({ publicApiClientsLoading: false }, false, APPLICATIONS_STORE_META_DATA.PUBLIC_API_CLIENTS_LOADING_STOP);
        }
      },

      fetchApplications: async ({ loading = true } = {}) => {
        const { userType } = useConfigurationStore.getState();
        set({ applicationLoading: loading }, false, APPLICATIONS_STORE_META_DATA.APPLICATIONS_LOADING_START);
        const { filters } = get();
        try {
          const params = omitBy(filters, isEmpty);
          const paginationParams = { ...params?.pagination };

          delete params?.pagination;
          delete paginationParams?.totalDocs;
          delete paginationParams?.totalPages;
          delete paginationParams?.previousPage;

          // Param is set here to reduce the occurrences of setting the filter for agencyId for applications in multiple places/files for differet use cases.
          if (userType === AgentPortalUserType.AGENCY) {
            const { profileData } = useProfileV2Store.getState();
            params.agencyId = [`${profileData?.agencyId}`];
          }

          const payload = {
            ...modifyApplicationsTableFilter(params),
            pagination: {
              ...paginationParams,
              pageSize: DEFAULT_PAGINATION.SIZE,
            },
            agencyNetworkId: [useProfileV2Store.getState().profileData?.agencyNetworkId || ''],
          };

          const response = await getAgencyNetworkApplications(payload);

          set(
            { applications: response.applications, pagination: response.pagination, applicationError: null },
            false,
            APPLICATIONS_STORE_META_DATA.APPLICATIONS_LOADED,
          );
        } catch (err: any) {
          set(
            { applications: undefined, pagination: undefined, applicationError: err },
            false,
            APPLICATIONS_STORE_META_DATA.APPLICATIONS_ERROR,
          );
        } finally {
          set({ applicationLoading: false }, false, APPLICATIONS_STORE_META_DATA.APPLICATIONS_LOADING_STOP);
        }
      },

      clearApplications: () =>
        set(
          {
            applicationLoading: false,
            applications: undefined,
            pagination: undefined,
            applicationError: null,
            publicApiClients: undefined,
            publicApiClientsLoading: false,
            // accountsLoading: false,
            // accounts: [],
            filters: undefined,
          },
          false,
          APPLICATIONS_STORE_META_DATA.CLEAR_APPLICATIONS,
        ),
    }),

    {
      name: APPLICATIONS_STORE_META_DATA.STORE_NAME,
      anonymousActionType: APPLICATIONS_STORE_META_DATA.ANONYMOUS_ACTION_NAME,
    },
  ),
);
